import { BenefitDef } from "@companies/types/companies.types";
import { EJourneyTypes } from "constants/journey.constants";
import { AddressDef } from "types/address.types";
import {
  CraftsmanFormAddressDef,
  RoleDef,
  TradesDef,
} from "types/craftsman.form.types";
import {
  CreateEditExternalLinkDef,
  ExternalLinkDef,
} from "types/external-links.types";
import { Optional } from "types/helpers.types";
import { TagDef } from "types/tag.types";

export enum EAuthStatus {
  LOADING = "loading",
  NO_USER = "no_user",
  AUTHENTICATED = "authenticated",
}

export enum EAuthFormType {
  LOGIN = "login",
  SIGN_UP = "sign-up",
  APPLY_TO_JOB = "apply-to-job",
}

export enum EDocumentType {
  CV = "cv",
  VISA = "visa",
  IMAGE = "image",
  OTHER = "other",
}

export type MediaDef = {
  id: string;
  url: string;
  filename: string;
  type: EDocumentType;
  order: number;
};

export type CreateEditMediaDef = Optional<MediaDef, "id" | "order">;

export type CreateCoordinatesDef = {
  lat: number;
  lng: number;
};
export type GeoSearchAddressDef = {
  country: string;
  country_original?: string;
  countryCode?: string;
  city: string;
  city_original?: string;
  radius?: number;
  lat: number;
  lng: number;
};

export type CreateAddressDef = {
  city: string;
  country: string;
  countryCode?: string;
};

export type AuthUserDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  slug: string;
  anonymousSlug: string;
  isAnonymous: boolean;
  isVerified: boolean;
  isReviewed: boolean;
  description: string | null;
  geoSearchAddress: GeoSearchAddressDef | null;
  address: AddressDef | null;
  picture: string | null;
  birthday: string | null;
  jobTitle?: TagDef;
  profileTags: TagDef[];
  profileTrades: TagDef[];
  preferredBenefits: BenefitDef[];
  medias: MediaDef[];
  externalLinks: ExternalLinkDef[];
  tutorialProgress: Record<string, boolean>;
};

// Requests
export type AuthLoginRequest = {
  jobAdId?: string;
  fromJourney?: EJourneyTypes;
  sendMagicLink?: boolean;
  password?: string;
} & (
  | {
      email: string;
      phone?: string | null;
    }
  | {
      email?: string | null;
      phone: string;
    }
);
export type AuthSignUpRequest = {
  name: string;
  lang?: string;
  address?: CreateAddressDef;
  coordinate?: CreateCoordinatesDef;
  geoSearchAddress?: GeoSearchAddressDef;
  jobTitleId?: string;
  tradeIds?: string[];
  medias?: CreateEditMediaDef[];
  externalLinks?: CreateEditExternalLinkDef[];
  fromJourney?: EJourneyTypes;
  utmParams?: Record<string, string>;
  waitForFullCreation?: boolean;
} & AuthLoginRequest;

export type UserEditRequest = {
  firstName?: string;
  lastName?: string;
  description?: string;
  picture?: string;
  profileTradesId?: string[];
  preferredBenefitsId?: string[];
  isAnonymous?: boolean;
  jobTitleId?: string;
  geoSearchAddress?: GeoSearchAddressDef;
  address?: CreateAddressDef;
  coordinate?: CreateCoordinatesDef;
  lang?: string;
  medias?: CreateEditMediaDef[];
  externalLinks?: CreateEditExternalLinkDef[];
  newPassword?: string;
  birthday?: string;
  tutorialProgress?: Record<string, boolean>;
  useWhatsApp?: boolean;
  isCompletingSignUp?: boolean;
};

// Responses
export type AuthUserResponse = {
  workerProfile: AuthUserDef;
  accessToken: string;
};
export type AuthLoginResponse =
  | {
      message: string;
    }
  | AuthUserResponse;

export type TokenPayloadDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  isCrafthuntCompanyAdmin: boolean;
  iat: number;
  exp: number;
  aud: "https://crafthunt.app";
  iss: "crafthunt";
  jti: string;
  company?: {
    id: string;
  };
};

export type AuthFormDef = {
  email: string;
  phone: string;
  language: string;
  password?: string;
  name?: string;
  isUsingEmail?: boolean;
  isSignUp?: boolean;
  isLoginWithPassword?: boolean;
  forgotPassword?: boolean;
};

export type AuthSignUpFormDef = {
  language: string;
  password: string;
  role: RoleDef;
  trades: TradesDef;
  address: CraftsmanFormAddressDef;
  version: number;
  travel: string;
  educations?: TagDef[];
  benefits?: BenefitDef[];
};

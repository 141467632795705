import clsx from "clsx";
import { ScreenSizes } from "constants/breakpoints";
import { Autoplay, Keyboard, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import styles from "./Carousel.module.scss";

export type SwiperBreakpoints = SwiperProps["breakpoints"];

export type CarouselProps = {
  endCallBack?: () => void;
  enableKeyboard?: boolean;
  withSmallNavigation?: boolean;
  withoutBreakpoints?: boolean;
  withoutAutoHeight?: boolean;
} & SwiperProps;

export const DEFAULT_BREAKPOINTS: SwiperBreakpoints = {
  [ScreenSizes.X_SMALL]: {
    slidesPerView: 1.15,
  },
  [ScreenSizes.SMALL]: {
    slidesPerView: 1.5,
  },
  [ScreenSizes.MEDIUM]: {
    slidesPerView: 3,
  },
  [ScreenSizes.X_LARGE]: {
    slidesPerView: 3,
  },
};

export const NO_BREAKPOINTS: SwiperBreakpoints = {
  [ScreenSizes.X_SMALL]: {
    slidesPerView: 1,
  },
  [ScreenSizes.SMALL]: {
    slidesPerView: 1,
  },
  [ScreenSizes.MEDIUM]: {
    slidesPerView: 1,
  },
  [ScreenSizes.X_LARGE]: {
    slidesPerView: 1,
  },
};

export const Carousel = ({
  breakpoints = DEFAULT_BREAKPOINTS,
  children,
  navigation,
  pagination,
  scrollbar,
  autoplay,
  endCallBack,
  spaceBetween = 16,
  slidesPerView = 3,
  className,
  enableKeyboard,
  withSmallNavigation,
  withoutBreakpoints,
  withoutAutoHeight,
  ...rest
}: CarouselProps) => {
  const handleOnReachEnd = () => {
    endCallBack?.();
  };

  return (
    <Swiper
      modules={[
        navigation && Navigation,
        pagination && Pagination,
        autoplay && Autoplay,
        scrollbar && Scrollbar,
        Keyboard,
      ].filter(Boolean)}
      navigation={navigation}
      pagination={pagination}
      scrollbar={scrollbar}
      slidesPerView={slidesPerView}
      autoplay={autoplay}
      spaceBetween={spaceBetween}
      breakpoints={withoutBreakpoints ? undefined : breakpoints}
      className={clsx(styles.swiper, className, {
        [styles.itemSwiper]: withSmallNavigation,
        [styles.itemSwiperAutoHeight]: !withoutAutoHeight,
      })}
      onReachEnd={handleOnReachEnd}
      keyboard={{
        enabled: enableKeyboard,
        pageUpDown: false,
      }}
      {...rest}
    >
      {children}
    </Swiper>
  );
};
export { SwiperSlide as CarouselSlide };

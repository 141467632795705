import { EJourneyTypes } from "constants/journey.constants";
import { StoryblokBlokDef } from "types/storyblok.types";
import { EAuthFormType } from "./auth.types";

export enum EAuthCTAButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export type AuthCTAButtonDef = StoryblokBlokDef<{
  text?: string;
  authType?: EAuthFormType;
  buttonType?: EAuthCTAButtonType;
  journey?: EJourneyTypes;
  withArrow?: boolean;
}>;

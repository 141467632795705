import {
  StorageTypeDef,
  getStorage,
  removeStorage,
  setStorage,
} from "context/context.helper";
import { useEffect, useState } from "react";

export function useStateWithStorage<T>(
  key: string,
  storageType: StorageTypeDef,
  initialData?: T
): [T, (newData: T) => void] {
  const [data, setData] = useState<T>(
    initialData || getStorage<T>(key, storageType)
  );

  useEffect(() => {
    if (initialData) {
      setStorage(key, initialData, storageType);
    }
  }, [initialData, key, storageType]);

  const handleSetData = (newData: T) => {
    setData(newData);
    if (newData) {
      setStorage(key, newData, storageType);
    } else {
      removeStorage(key, storageType);
    }
  };

  return [data, handleSetData];
}

/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";

const appStoreLanguages = [
  "cs",
  "da",
  "de",
  "el",
  "en",
  "es",
  "fr",
  "it",
  "pl",
  "ro",
];

const AppDownloadLink = () => {
  const router = useRouter();

  return (
    <div className="flex flex-wrap">
      <a
        className="h-[64px]"
        href="https://apps.apple.com/app/id1613904721"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="App Store"
          className="h-[64px] p-2.5"
          src={`/app-store/${
            appStoreLanguages.includes(router.locale.toLowerCase())
              ? router.locale.toLowerCase()
              : "en"
          }.svg`}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=app.crafthunt.crafthunt"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={`https://play.google.com/intl/en_us/badges/static/images/badges/${router.locale}_badge_web_generic.png`}
          className="h-[64px]"
          alt="Google Play Store"
        />
      </a>
    </div>
  );
};

export default AppDownloadLink;

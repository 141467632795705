import RoutesLoading from "@crafthunt-ui/RoutesLoading/RoutesLoading";
import "@crafthunt-ui/RoutesLoading/RoutesLoading.css";
import { GoogleTagManager } from "@next/third-parties/google";
import type { AnalyticsSnippet } from "@segment/analytics-next";
import { apiPlugin, storyblokInit } from "@storyblok/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { useMount } from "ahooks";
import Placeholder from "components/sections/Placeholder";
import { WebVitals } from "components/web-vitals/WebVitals";
import { dynamic_components } from "constants/dynamic-components.constants";
import { ENV, isProdOrStaging } from "constants/env";
import { MODAL_ID, TOOLTIP_ID } from "constants/ui.constants";
import { CandidateProvider } from "context/CandidateContext";
import { AuthModal } from "features/auth/components/AuthModal/AuthModal";
import { AuthProvider } from "features/auth/context/AuthContext";
import { AuthModalProvider } from "features/auth/context/AuthModalContext";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import { useRouter } from "next/router";
import posthog, { PostHogConfig } from "posthog-js";
import React, { useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import Bugsnag from "utils/bugsnag";
import throttle from "lodash/throttle";
import "../styles/tailwind.scss";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
  display: "swap",
});

storyblokInit({
  accessToken: ENV.STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: dynamic_components,
  apiOptions: {
    region: "eu",
  },
  enableFallbackComponent: true,
  customFallbackComponent: Placeholder,
});

declare global {
  interface Window {
    analytics: AnalyticsSnippet & PostHogConfig["segment"];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cookiebot: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    advPxl: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obApi: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hbspt: any;
  }
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const maxScrollPercentage = useRef(0);
  const maxScrollPixels = useRef(0);

  const getScrollData = () => {
    return {
      "max scroll percentage": maxScrollPercentage.current,
      "max scroll pixels": maxScrollPixels.current,
      "last scroll percentage": Math.min(
        1,
        (window.innerHeight + window.pageYOffset) / document.body.offsetHeight
      ),
      "last scroll pixels": window.innerHeight + window.pageYOffset,
      scrolled: maxScrollPixels.current > 0,
    };
  };

  useMount(() => {
    if (isProdOrStaging) {
      window.analytics?.ready(() => {
        posthog.init(ENV.POSTHOG_API_KEY, {
          api_host: "https://eu.i.posthog.com",
          segment: window.analytics,
          capture_pageview: false,
          capture_pageleave: false,
        });
        window.addEventListener("CookiebotOnDecline", () => {
          console.log("Opting out of capturing - decline");
          posthog.opt_out_capturing();
        });
        window.addEventListener("CookiebotOnAccept", () => {
          if (!window.Cookiebot?.consent?.statistics) {
            console.log("Opting out of capturing - accept but not statistics");
            posthog.opt_out_capturing();
          }
        });
      });
    }
  });

  useEffect(() => {
    const handleScroll = throttle(() => {
      const lastPercentage = Math.min(
        1,
        (window.innerHeight + window.pageYOffset) / document.body.offsetHeight
      );
      const lastPixels = window.innerHeight + window.pageYOffset;
      if (lastPercentage > maxScrollPercentage.current) {
        maxScrollPercentage.current = lastPercentage;
      }
      if (lastPixels > maxScrollPixels.current) {
        maxScrollPixels.current = lastPixels;
      }
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.analytics?.page(url);
      posthog.capture("left_page", getScrollData());
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const handlePageleave = () => {
      posthog.capture("$pageleave", getScrollData());
    };

    window.addEventListener("beforeunload", handlePageleave);

    return () => {
      window.removeEventListener("beforeunload", handlePageleave);
    };
  }, []);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx global>{`
        :root {
          --font-inter: ${inter.style.fontFamily};
        }
      `}</style>
      <ErrorBoundary>
        <CandidateProvider>
          <AuthProvider initialAccessToken={pageProps.accessToken}>
            <AuthModalProvider>
              <WebVitals />
              <RoutesLoading />
              <Toaster />
              <Component {...pageProps} />
              <SpeedInsights />
              <div id={MODAL_ID} />
              <Tooltip id={TOOLTIP_ID} className="crafthunt-tooltip" />
              <AuthModal />
              {isProdOrStaging && ENV.GOOGLE_TAG_MANAGER_KEY && (
                <GoogleTagManager gtmId={ENV.GOOGLE_TAG_MANAGER_KEY} />
              )}
            </AuthModalProvider>
          </AuthProvider>
        </CandidateProvider>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(MyApp);

import { isClientSide } from "utils/helpers";

export type StorageTypeDef = "localStorage" | "sessionStorage";

const parseJsonFromStorage = <T>(key: string, storageType: StorageTypeDef) => {
  try {
    // window and sessionStorage/localStorage might not been accessible
    // if browser is incognito or private mode
    const value =
      storageType === "sessionStorage"
        ? window?.sessionStorage?.getItem(key)
        : window?.localStorage?.getItem(key);
    if (value === null || value === undefined || value === "") {
      return undefined;
    }
    // only JSON Parse if it contains " or {
    return value.match(/\"|\{/) ? (JSON.parse(value) as T) : (value as T);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getStorage = <T>(key: string, storageType?: StorageTypeDef) => {
  if (!isClientSide()) {
    return null;
  }
  return parseJsonFromStorage<T>(key, storageType) || null;
};

export const setStorage = (
  key: string,
  data: unknown,
  storageType?: StorageTypeDef
) => {
  try {
    const stringified = JSON.stringify(data ?? "");
    if (storageType === "sessionStorage") {
      window?.sessionStorage?.setItem(key, stringified);
    } else {
      window?.localStorage?.setItem(key, stringified);
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeStorage = (key: string, storageType?: StorageTypeDef) => {
  try {
    if (storageType === "sessionStorage") {
      window?.sessionStorage?.removeItem(key);
    } else {
      window?.localStorage?.removeItem(key);
    }
  } catch (error) {
    console.error(error);
  }
};

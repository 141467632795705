import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { StoryblokLinkDef } from "types/storyblok.types";
import { removeEmpty } from "utils/helpers";
import { getTranslatedSlug } from "utils/router.helper";
import {
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  DocumentCheckIcon,
  DocumentMagnifyingGlassIcon,
  EyeSlashIcon,
  GlobeAltIcon,
  HandThumbUpIcon,
  InboxStackIcon,
  LightBulbIcon,
  LinkIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
  SparklesIcon,
  SwatchIcon,
  TagIcon,
} from "@heroicons/react/24/outline";

export const resolveIcon = {
  lightbulb: LightBulbIcon,
  eyeoff: EyeSlashIcon,
  thumbup: HandThumbUpIcon,
  swatch: SwatchIcon,
  chat: ChatBubbleLeftRightIcon,
  clipboard: ClipboardDocumentListIcon,
  "magnifying-glass": MagnifyingGlassIcon,
  "document-magnifying-glass": DocumentMagnifyingGlassIcon,
  clock: ClockIcon,
  "inbox-stack": InboxStackIcon,
  "arrow-path": ArrowPathIcon,
  sparkles: SparklesIcon,
  tag: TagIcon,
  link: LinkIcon,
  phone: PhoneIcon,
  "document-check": DocumentCheckIcon,
  "globe-alt": GlobeAltIcon,
};

export const SmallFeature = ({
  name,
  description,
  icon,
  link,
  linkText,
  darkTheme,
}: {
  name: string;
  description: string;
  icon: string;
  link?: StoryblokLinkDef;
  linkText?: string;
  darkTheme?: boolean;
}) => {
  const router = useRouter();

  const IconComp = resolveIcon[icon] ?? resolveIcon.lightbulb;

  return (
    <div className="pt-6">
      <div
        className={clsx("flow-root rounded-lg px-6 pb-8", {
          "bg-gray-50 text-gray-900": !darkTheme,
          "bg-white/5 text-white": darkTheme,
        })}
      >
        <div className="-mt-6">
          <div>
            <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-primary-500 to-primary-600 p-3 shadow-lg">
              <IconComp className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <h3 className="mt-8 text-lg font-medium tracking-tight">{name}</h3>
          <p
            className={clsx("mt-5 text-base", {
              "text-gray-500": !darkTheme,
              "text-gray-300": darkTheme,
            })}
          >
            {description}
          </p>
          {link && linkText && (
            <p className="mt-6">
              <Link
                href={{
                  pathname: getTranslatedSlug(link),
                  query: removeEmpty({
                    ...router.query,
                    slug: undefined,
                  }),
                }}
                className="text-sm/6 font-semibold text-primary-600"
              >
                {linkText} <span aria-hidden="true">→</span>
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

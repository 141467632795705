import * as React from "react";
import { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 79 79"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={39.5}
        y1={2}
        x2={39.5}
        y2={77}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0EAF2" />
        <stop offset={0.932} stopColor="#D5F4F8" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
    <path
      d="M39.5 77C60.21 77 77 60.21 77 39.5S60.21 2 39.5 2 2 18.79 2 39.5 18.79 77 39.5 77Z"
      fill="url(#a)"
    />
    <path
      d="M36.098 10.495a1.006 1.006 0 0 1-1.476.888 1.006 1.006 0 0 1-1.684.953 1.005 1.005 0 0 1-1.962-.109 1.008 1.008 0 0 1-1.08-.327.995.995 0 0 1-.87.015 1.004 1.004 0 0 1 .718-1.873 1.005 1.005 0 0 1 1.738-.819 1.005 1.005 0 0 1 1.952.056 1.006 1.006 0 0 1 1.044.42 1.005 1.005 0 0 1 1.62.796ZM57.52 8.09a.951.951 0 0 1-1.64.66 1.066 1.066 0 0 1-1.947.184 1.316 1.316 0 0 1-2.473.16.844.844 0 0 1-1.113-.225.812.812 0 0 1-.507.174.841.841 0 1 1 .402-1.58 1.061 1.061 0 0 1 1.327-.86 1.315 1.315 0 1 1 2.59-.435c.169.075.316.194.427.344a.95.95 0 0 1 1.552.733.952.952 0 0 1 1.382.845Z"
      fill="#EDF8F9"
    />
    <path d="M20.165 19.87H3.876v15.422h16.289V19.87Z" fill="#748CAB" />
    <path
      d="M76.05 22.177v3.717l-1.38.085-5.693.375-48.805 3.154v-7.33h55.879Z"
      fill="#BABABA"
    />
    <path
      d="m75.795 42.344-.546 6.869h-6.307l-.563-6.869h7.416Z"
      fill="#757575"
    />
    <path
      d="M75.897 57.754a3.892 3.892 0 0 1-2.778 2.76 2.89 2.89 0 0 1-.529.103c-.163.029-.328.04-.494.035a3.911 3.911 0 0 1-3.938-3.92.64.64 0 1 1 1.28 0 2.667 2.667 0 0 0 1.022 2.096 2.838 2.838 0 0 0 2.131.512c.068-.018.137-.035.222-.052a2.638 2.638 0 0 0 1.9-2.986 2.728 2.728 0 0 0-.418-1.07 2.594 2.594 0 0 0-1.704-1.108.495.495 0 0 0-.154-.034 1.091 1.091 0 0 1-.989-1.09v-3.787h1.28v3.631a3.932 3.932 0 0 1 3.17 4.91Z"
      fill="#597293"
    />
    <path d="M15.756 35.292H8.262v36.836h7.494V35.292Z" fill="#BABABA" />
    <path
      d="M20.169 19.865v1.753H5.622v13.67H3.87V19.865h16.3Z"
      fill="#8AA3BF"
    />
    <path d="M76.042 22.179H20.17v1.753h55.873v-1.753Z" fill="#E4E4E4" />
    <path d="M15.753 35.288H8.269v1.753h7.484v-1.753Z" fill="#A0A0A0" />
    <path
      d="M8.262 64.168v-28.8h7.518V65.21M20.165 19.87H3.876v15.422h16.289V19.87ZM8.262 37.288l7.494 7.494M8.262 46.279l7.494 7.494M8.262 55.27l7.494 7.494M76.058 25.894l-55.893 3.621v-7.334h55.893v3.713ZM75.246 49.204h-6.311l-.557-6.867h7.425l-.557 6.867ZM72.73 49.205v3.636a3.936 3.936 0 0 1 .397 7.682 4.165 4.165 0 0 1-1.034.133 3.934 3.934 0 0 1-3.936-3.934.64.64 0 0 1 1.28 0 2.642 2.642 0 0 0 2.147 2.604c.406.08.825.066 1.225-.041a2.603 2.603 0 0 0 1.844-1.845 2.66 2.66 0 0 0-2.214-3.35 1.105 1.105 0 0 1-.987-1.093v-3.792M68.97 26.418l.545 15.919M74.666 25.998v16.339"
      stroke="#08101E"
      strokeWidth={0.701}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.172 19.876v2.301h55.879v3.716l-1.38.085v16.365h1.124l-.545 6.87h-6.308l-.562-6.87h1.125l-.511-15.99h-.018l-48.803 3.155v5.78h-4.415v36.836H8.257V35.286H3.875v-15.41h16.297Zm55.623 22.467h.009ZM20.172 19H3.877a.876.876 0 0 0-.876.876v15.41a.876.876 0 0 0 .876.877h3.506v35.96a.876.876 0 0 0 .876.877h7.501a.876.876 0 0 0 .876-.876V36.163h3.539a.876.876 0 0 0 .876-.877V30.33l47.097-3.044.453 14.182h-.22a.877.877 0 0 0-.873.948l.562 6.87a.877.877 0 0 0 .874.805h6.308a.876.876 0 0 0 .873-.807l.539-6.782a.879.879 0 0 0-.523-.966.872.872 0 0 0-.335-.067h-.258V26.803l.559-.035a.877.877 0 0 0 .822-.875v-3.716a.877.877 0 0 0-.876-.876H21.049v-1.425a.876.876 0 0 0-.877-.876Z"
      fill="#08101E"
    />
  </svg>
);

const EmptyStateCompanies = memo(SvgComponent);
export { EmptyStateCompanies };

import { useEffect } from "react";

export const HubspotForm = ({
  blok,
}: {
  blok: {
    _uid: string;
    formId: string;
  };
}) => {
  const blokId = `hubspot-form-${blok._uid}`;
  const formId = blok.formId;

  useEffect(() => {
    // Dynamically load the HubSpot forms script
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "26849627",
          formId,
          target: `#${blokId}`,
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [formId, blokId]);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl bg-white px-6 py-24 lg:px-8 lg:pb-12">
        <div id={blokId}></div>
      </div>
    </div>
  );
};

import clsx from "clsx";
import { TOOLTIP_ID } from "constants/ui.constants";
import { useTranslation } from "next-i18next";
import { MdVerified } from "react-icons/md";

export enum EVerifiedBadgeType {
  AVATAR = "avatar",
  NAME = "name",
}
export enum EVerifiedBadgeSize {
  LARGE = "h-10 w-10",
  MEDIUM = "h-6 w-6",
  SMALL = "h-4 w-4",
}

type VerifiedBadgeProps = {
  type: EVerifiedBadgeType;
  size: EVerifiedBadgeSize;
};

export const VerifiedBadge = ({ type, size }: VerifiedBadgeProps) => {
  const { t } = useTranslation();
  return (
    <MdVerified
      className={clsx("rounded-full bg-white p-0 text-primary-500", size, {
        "absolute -right-1 -top-1 z-10": type === EVerifiedBadgeType.AVATAR,
        "mb-2 ml-1 inline": type === EVerifiedBadgeType.NAME,
      })}
      data-tooltip-id={TOOLTIP_ID}
      data-tooltip-content={t("verified-by-crafthunt")}
    />
  );
};

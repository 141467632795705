import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { EJourneyTypes } from "constants/journey.constants";
import { RoutesEnum } from "constants/routes.constants";
import { useCandidate } from "context/CandidateContext";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { StoryblokBlokDef } from "types/storyblok.types";
import {
  triggerFormSubmitNewsletter,
  triggerIdentifyByEmail,
} from "utils/analytics";
import { getQueryParam, removeEmpty } from "utils/helpers";
import { convertLocaleForAnalytics } from "utils/language.helper";
import { PhoneNumberInput } from "./PhoneNumberInput/PhoneNumberInput";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^\S+@\S+\.\S+$/);
};

type EmailSubmitFieldProps = {
  id?: string;
  blok: StoryblokBlokDef<{
    btnText: string;
    placeholderText: string;
    disclaimer: string;
    useOnDarkBackground: boolean;
    useWhiteTextForToggle: boolean;
  }>;
  hideDisclaimer?: boolean;
  fromJourney?: EJourneyTypes;
  showInputBorders?: boolean;
};

const EmailSubmitField = ({
  id,
  blok,
  hideDisclaimer,
  fromJourney,
  showInputBorders,
}: EmailSubmitFieldProps) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [isUsingEmail, setIsUsingEmail] = useState(false);
  const { t } = useTranslation();
  const { jobAdData, companyId } = useCandidate();
  const router = useRouter();

  useEffect(() => {
    const use = getQueryParam(router.query?.use)?.toLowerCase();
    if (use) {
      setIsUsingEmail(use === "email");
    }
  }, [router.query?.use]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isUsingEmail && !validateEmail(email)) {
      setInvalidEmail(true);
      return;
    }
    const isPhoneNumberInvalid = await import("utils/phone.helper").then(
      (mod) => mod.isPhoneNumberInvalid
    );
    if (!isUsingEmail && isPhoneNumberInvalid(phone)) {
      setInvalidPhone(true);
      return;
    }
    setInvalidEmail(false);
    setInvalidPhone(false);
    setLoading(true);
    const emailValue = isUsingEmail ? email : undefined;
    const phoneValue = !isUsingEmail ? phone : undefined;

    if (emailValue) {
      triggerIdentifyByEmail(emailValue, router.locale);
    }
    triggerFormSubmitNewsletter({
      email: emailValue,
      phone: phoneValue,
      url: router.asPath,
      locale: router.locale,
    });

    const isOnCompanyPage = router.pathname.includes("companies");
    const isOnJobPage = router.pathname.includes("jobs");
    const isOnCompanyOrJobPage = isOnCompanyPage || isOnJobPage;

    const urlParams = removeEmpty({
      ...router.query,
      ...(emailValue && { email: emailValue }),
      ...(phoneValue && { phone: phoneValue }),
      ...(isOnJobPage && jobAdData && { jobAdId: jobAdData.id }),
      ...(fromJourney && { fromJourney }),
      slug: undefined,
    });

    // If job ad or company id, take them directly to craftsman sign up form
    if (isOnCompanyOrJobPage && (jobAdData?.id || companyId)) {
      const hasJobQuestions = isOnJobPage && jobAdData?.jobQuestionsCount;
      // Go to job questions if job has questions
      // Otherwise directly to sign up
      router.push({
        pathname: hasJobQuestions
          ? RoutesEnum.JOB_QUESTIONS
          : RoutesEnum.COMPLETE_SIGN_UP,
        query: urlParams,
      });
    } else {
      // otherwise ask them if they are a craftsman or a company
      router.push({
        pathname: RoutesEnum.SIGNUP_CATEGORY,
        query: urlParams,
      });
    }
  };

  const toggleIsUsingEmail = () => {
    setIsUsingEmail((prevState) => !prevState);
  };

  const handleSetPhone = async (value: string) => {
    setPhone(value);

    if (invalidPhone) {
      const isPhoneNumberInvalid = await import("utils/phone.helper").then(
        (mod) => mod.isPhoneNumberInvalid
      );
      setInvalidPhone(isPhoneNumberInvalid(value));
    }
  };

  return (
    <form
      id={id || blok._uid}
      onSubmit={handleSubmit}
      className="sm:mx-auto sm:max-w-xl lg:mx-0"
    >
      <div className="mb-3 sm:flex">
        <div
          className={clsx("min-w-0 flex-1", {
            hidden: isUsingEmail,
          })}
        >
          <label htmlFor="email" className="sr-only">
            Phone number
          </label>
          <PhoneNumberInput
            id={`${id}_phone`}
            value={phone}
            onChange={handleSetPhone}
            className={clsx(
              "h-12 w-full rounded-sm px-4 py-3 text-base placeholder-gray-500 outline-none focus-within:ring-2 focus-within:ring-primary-600",
              {
                "bg-gray-900 text-white": blok.useOnDarkBackground,
                "bg-white text-gray-900": !blok.useOnDarkBackground,
                "ring-1 ring-inset ring-gray-300": showInputBorders,
              }
            )}
            inputClassName={`border-0 border-white`}
          />
        </div>
        <div
          className={clsx("min-w-0 flex-1", {
            hidden: !isUsingEmail,
          })}
        >
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            id={`${id}_email`}
            name="email"
            type="email"
            placeholder={blok.placeholderText}
            className={clsx(
              "block w-full rounded-sm border-0 px-4 py-3 text-base placeholder-gray-500 outline-none focus:ring-2 focus:ring-primary-600",
              {
                "bg-gray-900 text-white": blok.useOnDarkBackground,
                "bg-white text-gray-900": !blok.useOnDarkBackground,
                "ring-1 ring-inset ring-gray-300": showInputBorders,
              }
            )}
          />
          <input
            value={convertLocaleForAnalytics(router.locale)}
            id={`${id}_language`}
            name="language"
            type="hidden"
          />
        </div>
        <div className="mt-3 sm:ml-3 sm:mt-0">
          <button
            type="submit"
            className="block w-full rounded-sm bg-primary-600 px-4 py-3 font-medium text-white shadow-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 focus:ring-offset-gray-900"
            disabled={loading}
          >
            {loading ? (
              <CgSpinner className="h-7 w-7 animate-spin" />
            ) : (
              blok.btnText
            )}
          </button>
        </div>
      </div>
      <button
        type="button"
        className={clsx("space-x-1 text-sm font-semibold leading-6", {
          "text-white": blok.useWhiteTextForToggle,
          "text-primary-600": !blok.useWhiteTextForToggle,
        })}
        onClick={toggleIsUsingEmail}
      >
        {isUsingEmail ? (
          <>
            <span>{t("auth-use-phone-instead")}</span>
            <PhoneIcon className="mb-0.5 inline h-4 w-4" />
          </>
        ) : (
          <>
            <span>{t("auth-use-email-instead")}</span>
            <EnvelopeIcon className="mb-0.5 inline h-4 w-4" />
          </>
        )}
      </button>
      {!hideDisclaimer && !!blok.disclaimer && (
        <div
          className={`mb-3 mt-3 text-sm ${
            blok.useOnDarkBackground ? "text-gray-400" : "text-gray-400"
          } sm:mt-4`}
        >
          <Markdown>{blok.disclaimer}</Markdown>
        </div>
      )}
      {isUsingEmail && invalidEmail && (
        <div className="text-red-600">{t("form-field-email-invalid")}</div>
      )}
      {!isUsingEmail && invalidPhone && (
        <div className="text-red-600">{t("form-field-phone-invalid")}</div>
      )}
    </form>
  );
};
export default EmailSubmitField;

export enum RoutesEnum {
  FRONT_PAGE = "/",
  JOB_QUESTIONS = "/job-questions",
  SIGNUP_CATEGORY = "/signup",
  PERSONAL_LOGIN_SUCCESS = "/personal-login-success",
  BAUGPT = "/baugpt",
  COMPLETE_SIGN_UP = "/complete-sign-up",
}

export enum AuthRoutesEnum {
  JOBS = "/account/jobs",
  PROFILE = "/account/profile",
  SETTINGS = "/account/settings",
  MY_JOB_APPLICATIONS = "/my-job-applications",
  APPLY_TO_JOB = "/job-apply/[id]",
}

export const STORYBLOK_COMPANY_PAGES = [
  "/fuer-bauunternehmen",
  "/for-construction-companies",
  "/fuer-ingenieurbuero",
  "/fuer-elektrobetriebe-und-elektrounternehmen",
];

import clsx from "clsx";
import { Markdown } from "./Markdown/Markdown";

type SectionHeaderProps = {
  label?: string;
  description?: string;
  content?: string | string[];
  darkTheme?: boolean;
  align?: "left" | "center" | "right";
};

export const SectionHeader = ({
  label,
  description,
  content,
  darkTheme,
  align,
}: SectionHeaderProps) => {
  return (
    <div
      className={clsx({
        "text-gray-900": !darkTheme,
        "text-white": darkTheme,
        "text-center": !align || align === "center",
        "text-left": align === "left",
        "text-right": align === "right",
      })}
    >
      {!!label && (
        <p className="text-base font-semibold uppercase tracking-wider text-primary-500">
          {label}
        </p>
      )}
      <h2 className="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl">
        {description}
      </h2>
      {!!content &&
        (typeof content === "string" ? (
          <div
            className={clsx("mt-5 whitespace-pre-line text-xl", {
              "mx-auto max-w-[960px]": !align || align === "center",
              "text-gray-500": !darkTheme,
              "text-gray-400": darkTheme,
            })}
          >
            <Markdown>{content}</Markdown>
          </div>
        ) : (
          <p className="mx-auto mt-5 max-w-prose whitespace-pre-line text-xl text-gray-500">
            {content}
          </p>
        ))}
    </div>
  );
};

import * as React from "react";
import { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 79 79"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={39.5}
        y1={2}
        x2={39.5}
        y2={77}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0EAF2" />
        <stop offset={0.932} stopColor="#D5F4F8" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
    <path
      d="M39.5 77C60.21 77 77 60.21 77 39.5S60.21 2 39.5 2 2 18.79 2 39.5 18.79 77 39.5 77Z"
      fill="url(#a)"
    />
    <path
      d="M36.098 10.495a1.006 1.006 0 0 1-1.476.888 1.006 1.006 0 0 1-1.684.953 1.005 1.005 0 0 1-1.962-.109 1.008 1.008 0 0 1-1.08-.327.995.995 0 0 1-.87.015 1.004 1.004 0 0 1 .718-1.873 1.005 1.005 0 0 1 1.738-.819 1.005 1.005 0 0 1 1.952.056 1.006 1.006 0 0 1 1.044.42 1.005 1.005 0 0 1 1.62.796ZM57.52 8.09a.951.951 0 0 1-1.64.66 1.066 1.066 0 0 1-1.947.184 1.316 1.316 0 0 1-2.473.16.844.844 0 0 1-1.113-.225.812.812 0 0 1-.507.174.841.841 0 1 1 .402-1.58 1.061 1.061 0 0 1 1.327-.86 1.315 1.315 0 1 1 2.59-.435c.169.075.316.194.427.344a.95.95 0 0 1 1.552.733.952.952 0 0 1 1.382.845Z"
      fill="#EDF8F9"
    />
    <path
      d="M77 56.783V61H2v-4.893a6.521 6.521 0 0 1 .714-2.92 6.564 6.564 0 0 1 1.95-2.295L5.889 50h65.563l2.013 1.028a6.562 6.562 0 0 1 2.573 2.39A6.506 6.506 0 0 1 77 56.782Z"
      fill="#F59E0B"
    />
    <path
      d="M72 50H6c0-6.964 2.357-13.72 6.68-19.157a30.45 30.45 0 0 1 17.108-10.76l.22-.083 3.284 18.815h13.414l2.955-18.347.274.055a30.494 30.494 0 0 1 15.927 11.028A30.82 30.82 0 0 1 72 50Z"
      fill="#FEC215"
    />
    <path
      d="m49.954 19.225-.217 1.381L46.802 39H33.479l-3.263-18.863-.163-.856a3.71 3.71 0 0 1 .072-1.582c.138-.516.385-.995.725-1.403.34-.408.762-.736 1.24-.96A3.551 3.551 0 0 1 33.614 15H46.42a3.543 3.543 0 0 1 1.502.339c.47.221.888.544 1.224.946.336.401.582.872.722 1.38.14.508.169 1.04.086 1.56Z"
      fill="#F59E0B"
    />
    <path
      d="M76.999 60.748H2v-4.9a6.527 6.527 0 0 1 2.652-5.253l1.229-.906h65.573l2.013 1.039a6.53 6.53 0 0 1 3.533 5.8l-.001 4.22ZM29.521 20.135a30.296 30.296 0 0 0-23.64 29.553h65.573a30.298 30.298 0 0 0-21.918-29.114"
      stroke="#1A243A"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.663 38.635h-6.658l-3.428-19.409A3.599 3.599 0 0 1 33.122 15h6.376M39.663 38.635h6.658l3.166-19.457A3.6 3.6 0 0 0 45.934 15h-6.435"
      stroke="#1A243A"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EmptyStateCraftsmen = memo(SvgComponent);
export { EmptyStateCraftsmen };

import { createContext, ReactNode, useContext, useState } from "react";
import { EAuthFormType } from "../types/auth.types";

type AuthModalSettingsDef = {
  show: boolean;
  action?: EAuthFormType;
  onClose?: () => void;
  onSuccess?: () => void;
};

type AuthModalContextDef = {
  authModalSettings?: AuthModalSettingsDef;
  setAuthModalSettings: (value: AuthModalSettingsDef) => void;
};

const AuthModalContext = createContext<AuthModalContextDef | null>(null);

type AuthModalProviderProps = {
  children: ReactNode;
};

export function AuthModalProvider({ children }: AuthModalProviderProps) {
  const [authModalSettings, setAuthModalSettings] =
    useState<AuthModalSettingsDef>();

  return (
    <AuthModalContext.Provider
      value={{
        authModalSettings,
        setAuthModalSettings,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
}

export function useAuthModal() {
  return useContext(AuthModalContext) as AuthModalContextDef;
}

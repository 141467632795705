import { format, formatDistance, isAfter, isBefore } from "date-fns";
import {
  cs,
  da,
  de,
  el,
  es,
  fr,
  hr,
  hu,
  it,
  nb,
  nl,
  pl,
  ptBR,
  ro,
  ru,
  sk,
  sv,
  tr,
  uk,
} from "date-fns/locale";

export const getDateLocale = (
  locale:
    | "en" // English
    | "de" // German
    | "pl" // Polish
    | "ro" // Romanian
    | "es" // Spanish
    | "it" // Italian
    | "el" // Greek
    | "fr" // French
    | "cs" // Czech
    | "da" // Danish
    | "pt-br" // Portuguese (Brazil)
    | "tr" // Turkish
    | "uk" // Ukrainian
    | "sk" // Slovak
    | "hu" // Hungarian
    | "nl" // Dutch
    | "sv" // Swedish
    | "nb" // Norwegian
    | "hr" // Croatian
    | "ru" // Russian
    | string
) => {
  switch (locale) {
    case "de":
      return { locale: de };
    case "pl":
      return { locale: pl };
    case "ro":
      return { locale: ro };
    case "es":
      return { locale: es };
    case "it":
      return { locale: it };
    case "el":
      return { locale: el };
    case "fr":
      return { locale: fr };
    case "cs":
      return { locale: cs };
    case "da":
      return { locale: da };
    case "pt-br":
      return { locale: ptBR };
    case "tr":
      return { locale: tr };
    case "uk":
      return { locale: uk };
    case "sk":
      return { locale: sk };
    case "hu":
      return { locale: hu };
    case "nl":
      return { locale: nl };
    case "sv":
      return { locale: sv };
    case "nb":
      return { locale: nb };
    case "hr":
      return { locale: hr };
    case "ru":
      return { locale: ru };
    default:
      return undefined;
  }
};

export enum InputDateFormats {
  MONTH_DATE_INPUT = "yyyy-MM",
  DATE_INPUT = "yyyy-MM-dd",
}

export enum DisplayDateFormats {
  D_M_Y_SHORT = "P",
  Y_M_D = "yyyy-MM-dd",
  D_M_Y = "do MMM yyyy",
  M_Y = "MMM yyyy",
}

export const formatDate = (
  dateStr: string | null | undefined,
  locale: string,
  dateFormat: DisplayDateFormats | InputDateFormats = DisplayDateFormats.D_M_Y
) => {
  if (!dateStr) return dateStr;
  return format(new Date(dateStr), dateFormat, getDateLocale(locale));
};

export const isBetween = (
  startDate: string,
  endDate: string,
  dateToCompare: string
) => {
  const isDateAfter = isAfter(new Date(dateToCompare), new Date(startDate));
  const isDateBefore = isBefore(
    new Date(dateToCompare),
    endDate ? new Date(endDate) : new Date()
  );
  return isDateAfter && isDateBefore;
};

export const duration = (
  startDate: string,
  endDate: string,
  locale: string
) => {
  return formatDistance(new Date(startDate), new Date(endDate), {
    ...getDateLocale(locale),
    addSuffix: true,
  });
};

import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import clsx from "clsx";
import { resolveIcon, SmallFeature } from "./components/SmallFeature";
import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { storyblokEditable } from "@storyblok/react";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { getTranslatedSlug } from "utils/router.helper";

const LandFeatures = ({ blok, className = undefined, style = undefined }) => {
  const buttons = ((blok.cta && blok.cta.length > 0) || blok.buttonLink) && (
    <>
      <div className="mt-20">
        {blok.cta?.map((ctaBlok) => (
          <AuthCTAButton key={ctaBlok._uid} blok={ctaBlok} />
        ))}
        {blok.buttonLabel && (
          <CustomLink
            isCTA
            id={`cta-land-features-${blok._uid}`}
            href={getTranslatedSlug(blok.buttonLink)}
            target={blok.buttonLink?.target}
            className="text-md rounded-sm bg-primary-600 px-3.5 py-4 font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            {blok.buttonLabel}
          </CustomLink>
        )}
      </div>
      {blok.ctaDescription && (
        <div
          className={clsx("m-auto mt-6 max-w-prose text-sm", {
            "text-gray-500": !blok.darkTheme,
            "text-gray-400": blok.darkTheme,
          })}
        >
          <Markdown>{blok.ctaDescription}</Markdown>
        </div>
      )}
    </>
  );

  if (blok.variant === "simple") {
    return (
      <div
        className={clsx({
          "bg-white text-gray-900": !blok.darkTheme,
          "bg-gray-900 text-white": blok.darkTheme,
          "py-8 sm:py-12": blok.verticalPadding === "small",
          "py-16 sm:py-24": blok.verticalPadding === "medium",
          "py-24 sm:py-32":
            !blok.verticalPadding || blok.verticalPadding === "large",
        })}
        {...storyblokEditable(blok)}
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div
            className={clsx("mx-auto", {
              "lg:mx-0": blok.headerAlign && blok.headerAlign !== "center",
              "max-w-2xl": blok.headerAlign !== "right",
            })}
          >
            <SectionHeader
              label={blok.title}
              description={blok.description}
              content={blok.content}
              darkTheme={blok.darkTheme}
              align={blok.headerAlign}
            />
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base/7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-16">
            {blok.features.map((feature) => {
              const Icon = resolveIcon[feature.icon] ?? resolveIcon.lightbulb;

              return (
                <div
                  key={feature.name}
                  className={clsx("relative pl-9", {
                    "text-gray-900": !blok.darkTheme,
                    "text-white": blok.darkTheme,
                  })}
                >
                  <dt className="inline font-semibold">
                    <Icon
                      aria-hidden="true"
                      className="absolute left-1 top-1 size-5 text-primary-500"
                    />
                    {feature.name}
                  </dt>{" "}
                  <dd className={clsx(!blok.lineBreakAfterTitle && "inline")}>
                    {feature.description}
                  </dd>
                </div>
              );
            })}
          </dl>
          {buttons}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "relative",
        {
          "bg-white text-gray-900": !blok.darkTheme,
          "bg-gray-900 text-white": blok.darkTheme,
          "py-8 sm:py-12 lg:py-16": blok.verticalPadding === "small",
          "py-12 sm:py-16 lg:py-24": blok.verticalPadding === "medium",
          "py-16 sm:py-24 lg:py-32":
            !blok.verticalPadding || blok.verticalPadding === "large",
        },
        className
      )}
      style={style}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <SectionHeader
          label={blok.title}
          description={blok.description}
          content={blok.content}
          darkTheme={blok.darkTheme}
          align={blok.headerAlign}
        />
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {blok.features.map((feature) => (
              <SmallFeature
                key={feature.name}
                icon={feature.icon}
                name={feature.name}
                description={feature.description}
                link={feature.link}
                linkText={feature.linkText}
                darkTheme={blok.darkTheme}
              />
            ))}
          </div>
        </div>
        {buttons}
      </div>
    </div>
  );
};

export default LandFeatures;

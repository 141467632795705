import {
  Carousel,
  CarouselSlide,
  DEFAULT_BREAKPOINTS,
} from "@crafthunt-ui/Carousel/Carousel";
import { storyblokEditable } from "@storyblok/react";
import { useResponsive } from "ahooks";
import clsx from "clsx";
import { ScreenSizes } from "constants/breakpoints";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";

const LogoCloudSlider = ({
  blok,
  className = undefined,
  style = undefined,
}) => {
  const responsive = useResponsive();

  return (
    <div
      className={clsx("overflow-hidden bg-gray-100", className, {
        "bg-gray-100 text-gray-500": !blok.darkTheme,
        "bg-gray-900 text-gray-300": blok.darkTheme,
      })}
      style={style}
      {...storyblokEditable(blok)}
    >
      <div
        className={clsx("mx-auto max-w-7xl px-6 lg:px-8", {
          "py-6": blok.verticalPadding === "small",
          "py-12": blok.verticalPadding === "medium",
          "py-16": !blok.verticalPadding || blok.verticalPadding === "large",
        })}
      >
        <p
          className={clsx("mb-2 text-sm font-semibold sm:prose-base sm:mb-8", {
            "mx-auto max-w-xl text-center":
              !blok.labelAlign || blok.labelAlign === "center",
            "text-left": blok.labelAlign === "left",
            "text-right": blok.labelAlign === "right",
          })}
        >
          {blok.label}
        </p>

        <Carousel
          spaceBetween={responsive && !responsive.sm ? 30 : 100}
          autoplay={{
            delay: blok.autoplayInterval ?? 1000,
            disableOnInteraction: false,
          }}
          loop
          centeredSlides
          withoutAutoHeight
          breakpoints={{
            ...DEFAULT_BREAKPOINTS,
            [ScreenSizes.X_SMALL]: {
              slidesPerView: 3,
            },
            [ScreenSizes.SMALL]: {
              slidesPerView: 3,
            },
            [ScreenSizes.MEDIUM]: {
              slidesPerView: 5,
            },
            [ScreenSizes.X_LARGE]: {
              slidesPerView: blok.slidesPerViewDesktop ?? 6,
            },
          }}
        >
          {blok.logos?.map((item) => (
            <CarouselSlide
              key={item.id || item._uid}
              className="relative flex h-16 justify-center"
            >
              <NextImage
                className="h-full object-contain"
                src={transformImage(
                  item.filename,
                  "0x100/filters:grayscale():quality(80):fill(transparent)"
                )}
                alt={item.alt || "Company logo"}
                fill
                sizes="(max-width: 767px) 80vw, (max-width: 1200px) 33vw, 20vw"
              />
            </CarouselSlide>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default LogoCloudSlider;

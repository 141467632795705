import _kebabCase from "lodash/kebabCase";
import { nanoid } from "nanoid";

export const clearUpMetaText = (str: string, n: number) => {
  return truncate(str.replace(/\n/g, "").trim(), n);
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substring(0, n - 3).trim() + "..." : str;
};

export const isClientSide = () => {
  return typeof window !== "undefined";
};

export const getSlug = (title: string) => {
  return `${_kebabCase(title).replace(/ț/g, "t").replace(/ș/g, "s")}-${nanoid(
    8
  )}`;
};

export const getQueryParam = (param?: string | string[] | null) => {
  return typeof param === "string" ? param : undefined;
};

export const removeEmpty = <T>(obj: T, removeEmptySpace?: boolean) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) => v != null && (removeEmptySpace ? v !== "" : true)
    )
  ) as T;
};

export const isObjectEmpty = <T>(obj: T) => {
  return !obj || !Object.keys(removeEmpty(obj)).length;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const cookieEnabled = () => {
  // source: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/cookies.js
  // navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
  // configurations. For example, when blocking cookies via the Advanced
  // Privacy Settings in IE9, it always returns true. And there have been
  // issues in the past with site-specific exceptions.
  // Don't rely on it.

  // try..catch because some in situations `document.cookie` is exposed but throws a
  // SecurityError if you try to access it; e.g. documents created from data URIs
  // or in sandboxed iframes (depending on flags/context)
  try {
    // Create cookie
    document.cookie = "cookietest=1";
    const ret = document.cookie.indexOf("cookietest=") !== -1;
    // Delete cookie
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
  } catch {
    return false;
  }
};

export const removeDuplicates = (arr: string[]) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const removeWhitespace = (str?: string) => {
  return str?.replace(/ /g, "");
};

import { isDev, isStaging } from "constants/env";
import { LANGUAGES } from "constants/languages";
import { RoutesEnum } from "constants/routes.constants";
import { HTMLAttributeAnchorTarget } from "react";
import { LinkQueryDef } from "types/links.type";
import { ItemDef } from "types/storyblok.types";
import { removeEmpty } from "./helpers";

const REGEX_STARTS_WITH_ROOT_PAGE = /^land\//i;
const REGEX_CONTAINS_ROOT_PAGE = /\/land\//i;
const REGEX_CONTAINS_CRAFTHUNT = /(https?:\/\/|^)(www\.)?crafthunt\.app/i;
const REGEX_CONTAINS_LANGUAGE = /\/\w{2}(\-\w{2})?\//i;
const OLD_SIGN_UP = "/craftsman-signup";

const checkForLanguageInUrl = (url: string) => {
  if (REGEX_CONTAINS_LANGUAGE.test(url)) {
    return url.replace(REGEX_CONTAINS_LANGUAGE, "/");
  }
  return url;
};

export const getFullSlug = (fullSlug?: string, prefix?: string) => {
  if (!fullSlug || typeof fullSlug !== "string") return "/";
  let cleanUrl = fullSlug.toLowerCase();
  if (REGEX_STARTS_WITH_ROOT_PAGE.test(cleanUrl)) {
    cleanUrl = cleanUrl.replace(REGEX_STARTS_WITH_ROOT_PAGE, "");
  }
  if (REGEX_CONTAINS_ROOT_PAGE.test(cleanUrl)) {
    cleanUrl = cleanUrl.replace(REGEX_CONTAINS_ROOT_PAGE, "/");
  }
  // check if it's a crafthunt.app link
  if (REGEX_CONTAINS_CRAFTHUNT.test(cleanUrl)) {
    cleanUrl = cleanUrl.replace(REGEX_CONTAINS_CRAFTHUNT, "");
  }
  // if normal external link, then just return it
  else if (/^https?:\/\//i.test(cleanUrl)) {
    return cleanUrl;
  }

  cleanUrl = checkForLanguageInUrl(cleanUrl);
  cleanUrl = cleanUrl.replace(OLD_SIGN_UP, RoutesEnum.COMPLETE_SIGN_UP);
  if (cleanUrl.charAt(0) !== "/") {
    cleanUrl = `/${cleanUrl}`;
  }
  if (prefix && !cleanUrl.includes(prefix)) {
    cleanUrl = `/${prefix}${cleanUrl}`;
  }
  return cleanUrl;
};

export const extractQueryFromUrl = (url: string) => {
  const [splitUrl, splitQuery] = url.split("?");
  const searchParams = new URLSearchParams(splitQuery);
  const query: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    query[key] = value;
  });
  return {
    url: splitUrl,
    query,
  };
};

const appendUrlSearchParams = (url: string, query?: Record<string, string>) => {
  const cleanQuery = query && removeEmpty(query, true);
  if (cleanQuery && Object.keys(cleanQuery).length) {
    const [splitUrl, splitQuery] = url.split("?");
    const searchParams = new URLSearchParams(splitQuery);
    Object.keys(cleanQuery).map((queryKey) => {
      searchParams.append(queryKey, query[queryKey]);
    });
    url = `${splitUrl}?${searchParams.toString()}`;
  }
  return url;
};

export const getTranslatedSlug = (
  item: ItemDef | string,
  query?: LinkQueryDef,
  fallback?: string
): string => {
  let url: string = "";
  if (typeof item === "string") {
    url = item;
  } else if (item) {
    if ("url" in item && typeof item.url === "object") {
      url = getTranslatedSlug(item.url, query, fallback);
    } else if ("story" in item && item.story?.full_slug) {
      url = item.story.full_slug;
    } else if ("story" in item && item.story?.url) {
      url = item.story.url;
    } else if ("cached_url" in item && item.cached_url) {
      url = item.cached_url;
    } else if (typeof item.url === "string") {
      url = item.url;
    }
  }
  if (fallback) {
    if (url) {
      for (const language of Object.values(LANGUAGES)) {
        if (url.includes(`/${language.languageCode}/`)) {
          url = url.replace(`/${language.languageCode}/`, "/");
          break; // Stop after the first match
        }
      }
      if (url === "/") {
        url = fallback;
      }
    } else {
      url = fallback;
    }
  }

  // In case the url is a phone number or email, return it as is
  if (/^((tel|mailto)\:)/i.test(url)) {
    return url;
  }
  url = appendUrlSearchParams(url, query);
  return getFullSlug(url);
};

export const getBaseUrl = (locale?: string) => {
  const appendLocale = !locale || locale === "de" ? "" : `/${locale}`;
  if (isDev) {
    return `http://localhost:8080${appendLocale}`;
  }
  return `https://${isStaging ? "stg." : ""}crafthunt.app${appendLocale}`;
};

export const getLinkTarget = (
  url: string,
  target?: HTMLAttributeAnchorTarget
): HTMLAttributeAnchorTarget => {
  if (target) {
    return target;
  }
  if (url.charAt(0) === "/") {
    return "_self";
  }
  return "_blank";
};

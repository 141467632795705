import dynamic from "next/dynamic";
import type { PhoneNumberInputProps } from "./PhoneNumberInputLazy";
import { Loading } from "@crafthunt-ui/Loading/Loading";

export type { PhoneNumberInputProps } from "./PhoneNumberInputLazy";

const PhoneNumberInputLazy = dynamic(
  () => import("./PhoneNumberInputLazy").then((mod) => mod.PhoneNumberInput),
  { ssr: false, loading: () => <Loading className="h-full" /> }
);

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  return <PhoneNumberInputLazy {...props} />;
};

export type GeocodingResponseDef = {
  city?: string;
  municipality?: string;
  region?: string;
  postalCode?: string;
  country: string;
  countryCode: string;
  lat: number;
  lng: number;
};

export enum EGeocodingType {
  GEOCODE = "geocode",
  REVERSE_GEOCODE = "reverseGeocode",
}

import { StoryblokComponent } from "@storyblok/react";
import { CSSProperties } from "react";
import { StoryblokBlokDef } from "types/storyblok.types";

type RichTextContentProps = {
  blok: StoryblokBlokDef<{
    components: StoryblokBlokDef[];
  }>;
  locale: string;
  className?: string;
  style?: CSSProperties;
};

export const RichTextContent = ({
  blok,
  locale,
  className,
  style,
}: RichTextContentProps) => {
  return (
    <>
      {blok.components.map((component) => (
        <StoryblokComponent
          blok={component}
          key={component._uid}
          locale={locale}
          className={className}
          style={style}
        />
      ))}
    </>
  );
};

import {
  JOB_QUESTIONS_FORM_KEY,
  JOB_QUESTIONS_FORM_STORAGE_TYPE,
} from "@job-questions/constants/job-questions.constants";
import { JobQuestionAnswersFormDef } from "@job-questions/types/job-question-answers.types";
import { getStorage, removeStorage } from "context/context.helper";

export const getFormQuestionAnswers = () => {
  return getStorage<JobQuestionAnswersFormDef | undefined>(
    JOB_QUESTIONS_FORM_KEY,
    JOB_QUESTIONS_FORM_STORAGE_TYPE
  );
};

export const removeFormQuestionAnswers = () => {
  removeStorage(JOB_QUESTIONS_FORM_KEY, JOB_QUESTIONS_FORM_STORAGE_TYPE);
};

import { fetcher } from "constants/api";
import useSWR from "swr";
import { CraftsmanProfileDef } from "types/crafthunt-profile.types";
import { convertLocaleForBackend } from "utils/language.helper";

type GetCraftsmanProps = {
  idOrSlug?: string;
  lang?: string;
};

export function useGetCraftsman({ idOrSlug, lang }: GetCraftsmanProps) {
  const shouldFetchData = !!(idOrSlug && lang);
  const { data, isValidating, error } = useSWR<CraftsmanProfileDef>(
    shouldFetchData
      ? [
          `/crafthunts/slugs/${idOrSlug}`,
          { lang: convertLocaleForBackend(lang), type: "wp" },
        ]
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  return {
    craftsman: data || null,
    isLoading:
      shouldFetchData &&
      ((!error && typeof data === "undefined") || isValidating),
    error,
  };
}

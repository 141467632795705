import { fetcher } from "constants/api";
import { ENV } from "constants/env";
import useSWR from "swr";
import { JobAdDef } from "types/job-ad.types";
import { convertLocaleForBackend } from "utils/language.helper";

type GetJobAdProps = {
  idOrSlug?: string;
  lang?: string;
};

export function useGetJobAd({ idOrSlug, lang }: GetJobAdProps) {
  const shouldFetchData = !!(idOrSlug && lang);
  const { data, isValidating, error } = useSWR<JobAdDef>(
    shouldFetchData
      ? [
          `/crafthunts/job_ads/${idOrSlug}`,
          { lang: convertLocaleForBackend(lang) },
          {
            "x-access-token": ENV.BACKEND_API_KEY,
            Authorization: "",
          },
        ]
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  return {
    jobAd: data || null,
    isLoading:
      shouldFetchData &&
      ((!error && typeof data === "undefined") || isValidating),
    error,
  };
}

import { EShowcaseType, ShowcaseItemDef } from "../../Showcase";
import { ShowcaseItem } from "../ShowcaseItem/ShowcaseItem";
import { useGetShowcaseItem } from "./useGetShowcaseItem";

type ShowcaseItemProps = {
  slug?: string;
  type: EShowcaseType;
  showButton: boolean;
  locale: string;
  onClick: (item: ShowcaseItemDef) => void;
};

export const ShowcaseSpecificItem = ({
  slug,
  type,
  showButton,
  locale,
  onClick,
}: ShowcaseItemProps) => {
  const { data, isLoading } = useGetShowcaseItem({
    slug,
    type,
    lang: locale,
  });

  return (
    <ShowcaseItem
      isLoading={isLoading}
      item={data}
      showButton={showButton}
      onClick={() => onClick(data)}
    />
  );
};

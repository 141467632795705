import { AuthRoutesEnum } from "constants/routes.constants";
import { TokenPayloadDef } from "../types/auth.types";
import { Base64 } from "js-base64";

export const parseJwt = (token?: string) => {
  if (!token) {
    return null;
  }
  const [, base64Url] = token.split(".");
  if (!base64Url) {
    return null;
  }
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(Base64.decode(base64)) as TokenPayloadDef;
};

export const isAuthRoute = (path: string) => {
  return Object.values(AuthRoutesEnum).includes(path as AuthRoutesEnum);
};

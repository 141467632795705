import { useHasHydrated } from "hooks/useHasHydrated";
import NextHead from "next/head";

export const HubspotMeeting = ({ blok }) => {
  const hasHydrated = useHasHydrated();

  if (!hasHydrated) {
    return null;
  }

  const meetingLink: string = blok.embedMeetingLink?.url || "";

  const cleanMeetingLink = meetingLink.includes("?embed=true")
    ? meetingLink
    : `${meetingLink}?embed=true`;

  return (
    <>
      <NextHead>
        <script
          id="hubspot-meeting"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          async
        />
      </NextHead>
      <div key={`hubspot-meeting-${blok._uid}`} className="bg-gray-100 pt-12">
        <div
          className="meetings-iframe-container"
          data-src={cleanMeetingLink}
        />
      </div>
    </>
  );
};

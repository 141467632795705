import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import clsx from "clsx";
import {
  CompanyDef,
  SimpleCompanyDef,
} from "features/companies/types/companies.types";
import { CSSProperties } from "react";
import { CraftsmanProfileDef } from "types/crafthunt-profile.types";
import { JobAdDef } from "types/job-ad.types";
import { StoryblokBlokDef } from "types/storyblok.types";
import { triggerAnalyticsEvent } from "utils/analytics";
import { ShowcaseItem } from "./components/ShowcaseItem/ShowcaseItem";
import { ShowcaseSpecificItem } from "./components/ShowcaseSpecificItem/ShowcaseSpecificItem";
import { useGetShowcaseItems } from "./useGetShowcaseItems";

export enum EShowcaseType {
  JOBS = "jobs",
  COMPANIES = "companies",
  CRAFTSMEN = "craftsmen",
}

export type ShowcaseItemDef =
  | JobAdDef
  | CraftsmanProfileDef
  | CompanyDef
  | SimpleCompanyDef;

type ShowcaseSpecificItemDef = StoryblokBlokDef<{
  slug: string;
  type: EShowcaseType;
}>;

type ShowcaseProps = {
  blok: StoryblokBlokDef<{
    title?: string;
    description?: string;
    content?: string;
    specificItems?: ShowcaseSpecificItemDef[];
    type?: EShowcaseType;
    useVisitorsLocation?: boolean;
    coordinates?: string;
    radius?: string;
    trades?: string;
    roles?: string;
    maxItems?: "1" | "2" | "3";
    onlyShowVerifiedCraftsmen?: boolean;
    showButton?: boolean;
  }>;
  locale: string;
  className?: string;
  style?: CSSProperties;
};

export const Showcase = ({ blok, locale, className, style }: ShowcaseProps) => {
  const limit = Number(blok.maxItems || 3);
  const radius = Number(blok.radius || 50);
  const noSectionHeader = !blok.title && !blok.description && !blok.content;
  const { items, isLoading } = useGetShowcaseItems({
    type: blok.type,
    lang: locale,
    limit,
    isUseVisitorsLocation: blok.useVisitorsLocation,
    coordinates: blok.coordinates,
    radius,
    trades: blok.trades,
    roles: blok.roles,
    onlyShowVerifiedCraftsmen: blok.onlyShowVerifiedCraftsmen,
  });
  const itemsWithLoading: JobAdDef[] | SimpleCompanyDef[] = items.length
    ? items
    : [...new Array(limit)];

  if (!isLoading && !itemsWithLoading.length && !blok.specificItems?.length) {
    return null;
  }

  const onItemClicked = (item: ShowcaseItemDef) => {
    triggerAnalyticsEvent("showcase_clicked", {
      type: blok.type,
      origin: window.location.pathname,
      destination: item.slug,
      lang: locale,
      id: item.id,
      useVisitorsLocation: blok.useVisitorsLocation,
      coordinates: blok.coordinates,
      trades: blok.trades,
      roles: blok.roles,
      onlyShowVerifiedCraftsmen: blok.onlyShowVerifiedCraftsmen,
    });
  };

  return (
    <div
      className={clsx(
        "relative bg-gray-50",
        className,
        noSectionHeader ? "py-16" : "py-16 sm:py-24 lg:py-32"
      )}
      style={style}
    >
      <div className="relative">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <SectionHeader
            label={blok.title}
            description={blok.description}
            content={blok.content}
          />
        </div>
        <div
          className={clsx(
            "mx-auto flex max-w-md flex-row flex-wrap justify-center gap-4 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:flex-nowrap lg:px-8",
            !noSectionHeader && "mt-12"
          )}
        >
          {blok.specificItems?.map((item) => (
            <ShowcaseSpecificItem
              key={item._uid}
              slug={item.slug}
              type={item.type}
              locale={locale}
              showButton={blok.showButton}
              onClick={onItemClicked}
            />
          ))}
          {!blok.specificItems?.length &&
            itemsWithLoading.map((item: ShowcaseItemDef, index: number) => (
              <ShowcaseItem
                key={(item && item.id) || index}
                item={item}
                isLoading={isLoading}
                showButton={blok.showButton}
                onClick={() => onItemClicked(item)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

import { reverseGeocode } from "@geocoding/api/geocoding.api";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { ENV } from "constants/env";
import { fetchContinentByCountryCode } from "features/continents/api/continents.api";
import { CoordinatesDef } from "types/address.types";

const axios = setupCache(Axios);

type GeoDataDef = {
  city: string;
  country: string;
  countryCode: string;
  postalCode: string;
  isInEurope: boolean;
  lat: number;
  lng: number;
};

type GeolocationDef = {
  location: CoordinatesDef;
  accuracy: number;
};

const getIsInEurope = async (countryCode: string) => {
  try {
    const res = await fetchContinentByCountryCode(countryCode);
    return res.continent === "EU";
  } catch (error) {
    console.log(error);
  }
  // in case it fails, then allow user to apply
  return true;
};

export const fetchGeoData = async (
  config: { lang?: string; shouldNotReverseGeocode?: boolean } = {}
): Promise<Partial<GeoDataDef>> => {
  try {
    const res = await axios.post<Partial<GeolocationDef>>(
      "https://www.googleapis.com/geolocation/v1/geolocate",
      { considerIp: true },
      {
        id: "geolocation",
        params: {
          key: ENV.GOOGLE_MAPS_API_KEY,
        },
      }
    );
    if (res.data.location) {
      if (config.shouldNotReverseGeocode) {
        return {
          lat: res.data.location.lat,
          lng: res.data.location.lng,
        };
      }

      const reverseGeocodingRes = await reverseGeocoding(
        res.data.location.lat,
        res.data.location.lng,
        config.lang || "en"
      );

      const isInEurope = await getIsInEurope(reverseGeocodingRes.countryCode);

      return {
        city: reverseGeocodingRes.city,
        country: reverseGeocodingRes.country,
        countryCode: reverseGeocodingRes.countryCode,
        postalCode: reverseGeocodingRes.postalCode,
        lat: res.data.location.lat,
        lng: res.data.location.lng,
        isInEurope,
      };
    }
  } catch (e) {
    console.log(e);
  }
  return {} as Partial<GeoDataDef>;
};

export const reverseGeocoding = async (
  lat: number,
  lng: number,
  lang: string
) => {
  try {
    return reverseGeocode(lat, lng, lang);
  } catch (error) {
    console.log(error);
  }
  return null;
};

import clsx from "clsx";

type SpacerProps = {
  blok: {
    hideOnPhone: boolean;
    hideOnTablet: boolean;
    order?: string;
    height?: string;
  };
};

export const Spacer = ({ blok }: SpacerProps) => {
  return (
    <div
      className={clsx({
        hidden: blok.hideOnPhone || blok.hideOnTablet,
        "md:block": blok.hideOnPhone && !blok.hideOnTablet,
        "lg:block": blok.hideOnTablet,
        "h-28": !blok.height,
      })}
      style={{ height: blok.height ? Number(blok.height) : undefined }}
    />
  );
};

import { apiCached } from "constants/api";
import { ContinentDef } from "../types/continent.types";

export const fetchContinentByCountryCode = (
  countryCode: string
): Promise<ContinentDef> => {
  return apiCached
    .get<ContinentDef>("/crafthunts/continents", {
      id: countryCode,
      params: { countryCode },
    })
    .then((resp) => resp.data);
};

import packageJson from "../package.json";

export const ENV = {
  VERSION: packageJson.version || "",
  BACKEND_API: process.env.NEXT_PUBLIC_BACKEND_API ?? "",
  BACKEND_API_KEY: process.env.NEXT_PUBLIC_BACKEND_API_KEY ?? "",
  ANALYTICS_WRITE_KEY: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY ?? "",
  STORYBLOK_ACCESS_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN ?? "",
  ENVIRONMENT: process.env.NODE_ENV ?? "development",
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? "",
  GOOGLE_TAG_MANAGER_KEY: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY ?? "",
  BUGSNAG_API_KEY: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? "",
  IS_STAGING: process.env.NEXT_PUBLIC_IS_STAGING === "1",
  MAINTENANCE: process.env.NEXT_PUBLIC_MAINTENANCE ?? "",
  POSTHOG_API_KEY: process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? "",
  TIKTOK_KEY: process.env.NEXT_PUBLIC_TIKTOK_KEY ?? "",
  FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? "",
  FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? "",
  FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? "",
  FIREBASE_STORAGE_BUCKET:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? "",
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ?? "",
  FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? "",
  FIREBASE_MEASUREMENT_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ?? "",
  SKIP_BUILD_STATIC_GENERATION: process.env.SKIP_BUILD_STATIC_GENERATION ?? "",
};

export const isDev = ENV.ENVIRONMENT === "development";
export const isProd = ENV.ENVIRONMENT === "production" && !ENV.IS_STAGING;
export const isStaging = ENV.ENVIRONMENT === "production" && ENV.IS_STAGING;
export const isProdOrStaging = ENV.ENVIRONMENT === "production";

import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ENV } from "constants/env";

Bugsnag.start({
  apiKey: ENV.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ["production"],
  appVersion: ENV.VERSION,
  redactedKeys: ["newPassword"],
});

export default Bugsnag;

import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import dynamic from "next/dynamic";

const DynamicIcon = dynamic(
  () => import("components/icons/DynamicIcon").then((mod) => mod.DynamicIcon),
  {
    ssr: false,
  }
);

const Feature = ({ blok, className = undefined, style = undefined }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      key={blok._uid}
      className={clsx(
        "flex max-w-sm flex-col items-center p-2 py-16 text-center sm:p-10",
        className
      )}
      style={style}
    >
      <DynamicIcon type={blok.icon} />
      <div className="px-6 py-4">
        <div className="my-4 text-xl font-bold">{blok.name}</div>
        <p className="text-base text-gray-600">{blok.description}</p>
      </div>
    </div>
  );
};

export default Feature;

import clsx from "clsx";
import { CSSProperties } from "react";

type StatDef = {
  stat?: string;
  title?: string;
  description?: string;
};

type StatsSectionProps = {
  blok: {
    title?: string;
    description?: string;
    stats?: StatDef[];
  };
  className?: string;
  style?: CSSProperties;
};

export const StatsSection = ({
  blok: { title, description, stats },
  className,
  style,
}: StatsSectionProps) => {
  return (
    <div className={clsx("bg-white py-24 sm:py-32", className)} style={style}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          {!!title && (
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </h2>
          )}
          {!!description && (
            <p className="mt-6 text-base leading-7 text-gray-600">
              {description}
            </p>
          )}
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
          {stats?.map((stat, index) => (
            <div
              key={stat.stat + index}
              className={clsx(
                "flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-t-2xl p-8",
                {
                  "bg-gray-100 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:max-w-none lg:flex-auto lg:flex-col lg:items-start":
                    index === 0 || index > 2,
                  "bg-gray-900 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44":
                    index === 1,
                  "bg-primary-600 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28":
                    index === 2,
                }
              )}
            >
              {!!stat.stat && (
                <p
                  className={clsx(
                    "flex-none text-3xl font-bold tracking-tight",
                    {
                      "text-gray-900": index === 0 || index > 2,
                      "text-white": [1, 2].includes(index),
                    }
                  )}
                >
                  {stat.stat}
                </p>
              )}
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                {!!stat.title && (
                  <p
                    className={clsx("text-lg font-semibold tracking-tight", {
                      "text-gray-900": index === 0 || index > 2,
                      "text-white": [1, 2].includes(index),
                    })}
                  >
                    {stat.title}
                  </p>
                )}
                {!!stat.description && (
                  <p
                    className={clsx("mt-2 text-base leading-7", {
                      "text-gray-600": index === 0 || index > 2,
                      "text-gray-400": index === 1,
                      "text-gray-200": index === 2,
                    })}
                  >
                    {stat.description}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { ISbRichtext, StoryblokComponent } from "@storyblok/react";
import clsx from "clsx";
import { DynamicComponentNames } from "constants/dynamic-components.constants";
import { CSSProperties } from "react";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";
import { StoryblokSettingsDef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";
import styles from "./RichText.module.scss";

export type RichTextProps = {
  content: {
    type: "doc";
    content: ISbRichtext[];
  };
  maxWidth?: number;
  locale: string;
  settings?: StoryblokSettingsDef;
};

const ALLOW_PROSE: DynamicComponentNames[] = ["Text", "featuretailwind"];
const DISABLE_FULL_WIDTH: DynamicComponentNames[] = ["RichTextContent"];

export const RichText = ({
  content,
  maxWidth,
  locale,
  settings,
}: RichTextProps) => {
  // TODO: Temporary fix for broken richtext content while we are running n8n translations
  const cleanContent = { ...content };
  cleanContent.content = cleanContent.content.map((item) => {
    if (" attrs" in item) {
      item.attrs = item[" attrs"];
      delete item[" attrs"];
    }
    if (item.type === "paragraph" && item.content) {
      // Content is just a string
      if (typeof item.content === "string") {
        return {
          ...item,
          content: [{ text: item.content, type: "text" }],
        };
      }
      // Check if content is array
      if (Array.isArray(item.content)) {
        // If empty array, return paragraph without content
        if (!item.content.length) {
          return {
            type: "paragraph",
          };
        }
        item.content = item.content.map((contentItem) => {
          if (typeof contentItem === "string") {
            return { text: contentItem, type: "text" };
          }
          if (!("type" in contentItem) || !contentItem.type) {
            return { ...contentItem, type: "text" };
          }
          return contentItem;
        });
        return item;
      }
      // Check if content is object
      if (typeof item.content === "object") {
        if ("text" in item.content) {
          return {
            ...item,
            content: [
              "type" in item.content
                ? item.content
                : { ...(item.content as ISbRichtext), type: "text" },
            ],
          };
        } else {
          return {
            type: "paragraph",
          };
        }
      } else {
        return {
          type: "paragraph",
        };
      }
    }
    return item;
  });
  return (
    <div className={styles.richtext}>
      {render(cleanContent, {
        markResolvers: {
          [MARK_LINK]: (children, { uuid, href, target, linktype }) => {
            if (linktype === "email") {
              return (
                <a id={uuid} href={`mailto:${href}`}>
                  {children}
                </a>
              );
            }
            if (target === "_self" && href.charAt(0) === "#") {
              return (
                <a id={uuid} href={href} target={target}>
                  {children}
                </a>
              );
            }
            return (
              <CustomLink
                id={uuid}
                href={getTranslatedSlug(href)}
                target={target}
              >
                {children}
              </CustomLink>
            );
          },
        },
        defaultBlokResolver: (name, props) => {
          const blok = { ...props, component: name };
          const disableProse = !ALLOW_PROSE.includes(
            name as DynamicComponentNames
          );
          const isFullWidth = !DISABLE_FULL_WIDTH.includes(
            name as DynamicComponentNames
          );

          return (
            <StoryblokComponent
              blok={blok}
              settings={settings}
              key={props._uid}
              locale={locale}
              className={clsx(
                disableProse && "not-prose",
                isFullWidth && styles.fullWidthContainer
              )}
              style={
                {
                  "--blog-max-width": isFullWidth ? `${maxWidth}px` : undefined,
                } as CSSProperties
              }
            />
          );
        },
      })}
    </div>
  );
};

import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import MarkdownReact from "marked-react";
import type { CustomReactRenderer } from "marked-react/dist/ReactRenderer";
import { getTranslatedSlug } from "utils/router.helper";
import { v4 as uuidv4 } from "uuid";

type MarkdownProps = {
  openLinksInNewTab?: boolean;
  children?: string;
  renderer?: CustomReactRenderer;
};

export const Markdown = ({
  openLinksInNewTab,
  renderer,
  children,
}: MarkdownProps) => {
  return (
    <MarkdownReact
      renderer={{
        table: (children) => (
          <div key={`table-${uuidv4()}`} className="overflow-x-auto">
            <table>{children}</table>
          </div>
        ),
        link: (href, text) => (
          <CustomLink
            key={`link-${uuidv4()}`}
            href={getTranslatedSlug(href)}
            className="underline md:hover:text-black"
            target={openLinksInNewTab ? "_blank" : "_self"}
          >
            {text}
          </CustomLink>
        ),
        ...renderer,
      }}
    >
      {children}
    </MarkdownReact>
  );
};

import { fetcher } from "constants/api";
import { ENV } from "constants/env";
import useSWR from "swr";
import { isObjectEmpty, removeEmpty } from "utils/helpers";
import { convertLocaleForBackend } from "utils/language.helper";
import {
  CompaniesRequestDef,
  CompaniesResponseDef,
} from "../types/companies.types";

export function useGetCompanies({
  shouldFetch = true,
  ...params
}: CompaniesRequestDef & {
  shouldFetch?: boolean;
}) {
  const cleanedParams = removeEmpty(params);
  const shouldFetchData = shouldFetch && !isObjectEmpty(cleanedParams);
  const { data, isValidating, error } = useSWR<CompaniesResponseDef>(
    shouldFetchData
      ? [
          "/crafthunts/company_resources",
          {
            ...cleanedParams,
            lang: params.lang
              ? convertLocaleForBackend(params.lang)
              : undefined,
          },
          {
            // TODO: Remove this, when removed from backend
            "x-access-token": ENV.BACKEND_API_KEY,
            Authorization: "",
          },
        ]
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  return {
    companies: data?.data || [],
    isLoading:
      shouldFetchData &&
      ((!error && typeof data === "undefined") || isValidating),
    error,
  };
}

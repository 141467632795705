import { CustomButton } from "@crafthunt-ui/CustomButton/CustomButton";
import { StoryblokBlokDef, StoryblokLinkDef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";

export type NavbarCTADef = StoryblokBlokDef<{
  text: string;
  link: StoryblokLinkDef;
  style: "primary" | "secondary";
}>;

type NavbarCTAProps = {
  blok: NavbarCTADef;
};

export const NavbarCTA = ({ blok }: NavbarCTAProps) => {
  return (
    <CustomButton
      isCTA
      id={`navbar-cta-${blok._uid}`}
      href={getTranslatedSlug(blok.link)}
      target={blok.link?.target}
      buttonStyle={blok.style}
    >
      {blok.text}
    </CustomButton>
  );
};

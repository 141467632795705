import { useGetCompany } from "features/companies/hooks/useGetCompany";
import { CompanyDef } from "features/companies/types/companies.types";
import { useGetCraftsman } from "features/craftsmen/hooks/useGetCraftsman";
import { useGetJobAd } from "features/job-ads/hooks/useGetJobAd";
import { CraftsmanProfileDef } from "types/crafthunt-profile.types";
import { JobAdDef } from "types/job-ad.types";
import { EShowcaseType } from "../../Showcase";

type GetShowcaseItemProps = {
  slug: string;
  type: EShowcaseType;
  lang: string;
};

export function useGetShowcaseItem({
  slug,
  type,
  lang,
}: GetShowcaseItemProps): {
  data: JobAdDef | CompanyDef | CraftsmanProfileDef;
  isLoading: boolean;
} {
  const splitSlug = slug.split("/");
  const actualSlug = splitSlug[splitSlug.length - 1];
  // Get job
  const { jobAd, isLoading: jobIsLoading } = useGetJobAd({
    idOrSlug: type === EShowcaseType.JOBS ? actualSlug : null,
    lang,
  });
  // Get company
  const { company, isLoading: companyIsLoading } = useGetCompany({
    idOrSlug: type === EShowcaseType.COMPANIES ? actualSlug : null,
    lang,
  });
  // Get craftsman
  const { craftsman, isLoading: craftsmanIsLoading } = useGetCraftsman({
    idOrSlug: type === EShowcaseType.CRAFTSMEN ? actualSlug : null,
    lang,
  });

  if (type === EShowcaseType.JOBS) {
    return {
      data: jobAd || null,
      isLoading: jobIsLoading,
    };
  }
  if (type === EShowcaseType.COMPANIES) {
    return {
      data: company || null,
      isLoading: companyIsLoading,
    };
  }
  return {
    data: craftsman || null,
    isLoading: craftsmanIsLoading,
  };
}

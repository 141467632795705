import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";

const PolicyPage = ({ blok }) => {
  const hasImage = !!blok.image?.filename;
  return (
    <div className="relative bg-white">
      {hasImage && (
        <div className="relative h-56 lg:absolute lg:inset-0 lg:h-full">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <NextImage
              className="object-cover"
              src={transformImage(
                blok.image.filename,
                "1500x0/filters:quality(80)"
              )}
              alt=""
              fill
              sizes="(max-width: 1023px) 100vw, 50vw"
            />
          </div>
        </div>
      )}
      <div
        className={`relative px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:mx-auto lg:max-w-7xl lg:px-8 ${
          hasImage ? "lg:grid lg:grid-cols-2" : ""
        }`}
      >
        <div className={hasImage ? "lg:col-start-2 lg:pl-8" : ""}>
          <div
            className={`mx-auto max-w-prose text-base ${
              hasImage ? "lg:ml-auto lg:mr-0 lg:max-w-lg" : ""
            }`}
          >
            <h2 className="font-semibold uppercase leading-6 tracking-wide text-primary-600">
              {blok.subtitle}
            </h2>
            <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              {blok.header}
            </h3>
            <div className="prose prose-indigo mt-5 text-gray-500">
              <Markdown>{blok.body}</Markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPage;

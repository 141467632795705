import clsx from "clsx";
import { ReactNode } from "react";
import { CgSpinner } from "react-icons/cg";

export enum ELoadingSize {
  SMALL,
  LARGE,
}

type LoadingProps = {
  isSkeleton?: boolean;
  children?: ReactNode;
  withSpacing?: boolean;
  className?: string;
  isWhite?: boolean;
  size?: ELoadingSize;
};

export const Loading = ({
  isSkeleton,
  children,
  withSpacing = true,
  className,
  isWhite,
  size = ELoadingSize.SMALL,
}: LoadingProps) => {
  if (isSkeleton) {
    return (
      <div
        className={clsx(
          "border-lg relative flex animate-pulse flex-col justify-end overflow-hidden rounded-lg bg-white shadow",
          className
        )}
      >
        {children}
      </div>
    );
  }
  return (
    <div className={clsx("flex items-center justify-center", className)}>
      <CgSpinner
        className={clsx("animate-spin", {
          "-ml-1 mr-3": withSpacing,
          "text-white": isWhite,
          "size-6": size === ELoadingSize.SMALL,
          "size-12": size === ELoadingSize.LARGE,
        })}
      />
    </div>
  );
};

import { ReactNode } from "react";
import reactStringReplace from "react-string-replace";

export const interpolation = (key: string, values: (string | number)[]) => {
  const regex = /{\d}/g; // {0}, {1}, and so on
  const regexNumber = /\d/; // find number

  const matches = key.match(regex);
  // only do interpolation if we found as many matches as values are passed in
  if (matches?.length === values.length) {
    matches?.forEach((match) => {
      const matchIndex = match.match(regexNumber)?.[0];
      if (values[matchIndex]) {
        key = key.replace(match, values[matchIndex].toString());
      }
    });
  }

  return key;
};

export const interpolationWithComp = (key: string, values: ReactNode[]) => {
  const regex = /({\d})/g; // {0}, {1}, and so on
  const regexNumber = /\d/; // find number
  return reactStringReplace(key, regex, (match) => {
    const matchIndex = match.match(regexNumber)?.[0];
    return values[matchIndex];
  });
};

export const hasOriginalText = (
  translatedText: string,
  originalText?: string | null
) => {
  return !!originalText && translatedText !== originalText;
};

import { getStorage, removeStorage } from "context/context.helper";
import {
  USER_INFO_FORM_KEY,
  USER_INFO_FORM_STORAGE_TYPE,
} from "../constants/user-info-form.constants";
import { UserInfoFormDef } from "../types/user-info-form.types";

export const getFormUserInfo = () => {
  return getStorage<UserInfoFormDef | undefined>(
    USER_INFO_FORM_KEY,
    USER_INFO_FORM_STORAGE_TYPE
  );
};

export const removeFormUserInfo = () => {
  removeStorage(USER_INFO_FORM_KEY, USER_INFO_FORM_STORAGE_TYPE);
};

import { useRouter } from "next/router";
import NProgress from "nprogress";
import { memo, useEffect } from "react";

const RoutesLoading = () => {
  const router = useRouter();

  useEffect(() => {
    const routeChangeStart = (
      _url: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (!shallow) {
        NProgress.start();
      }
    };
    const routeChangeComplete = (
      _url: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (!shallow) {
        NProgress.done();
      }
    };

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeComplete);
    router.events.on("routeChangeError", routeChangeComplete);
    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeComplete);
      router.events.off("routeChangeError", routeChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default memo(RoutesLoading);

import axios, { AxiosRequestConfig } from "axios";
import { setupCache } from "axios-cache-interceptor";
import { ENV } from "./env";

const axiosConfig: AxiosRequestConfig = {
  baseURL: ENV.BACKEND_API,
  headers: {
    registerapp: "crafthunt",
  },
};

const api = axios.create(axiosConfig);

export const apiCached = setupCache(axios.create(axiosConfig));

export const setAuthTokenForApiInstance = (accessToken: string | null) => {
  if (accessToken) {
    api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  } else {
    api.defaults.headers.common["Authorization"] = "";
  }
};

/** Fetching data from Backend API */
export const fetcher = <T>(
  url: string,
  params?: Record<string, unknown>,
  headers?: Record<string, string>,
  skipRetry?: boolean
): Promise<T> =>
  api
    .get<T>(url, {
      params,
      headers,
    })
    .then((res) => {
      if (!skipRetry && (res.status === 401 || res.status == 403)) {
        // We try without token
        setAuthTokenForApiInstance(null);
        return fetcher<T>(url, params, headers, true);
      }
      return res.data;
    });

/** Posting data to Backend API */
export const post = <T>(
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => api.post<T>(url, data, config).then((res) => res.data);

export const put = <T>(
  url: string,
  data: Record<string, unknown>,
  config?: AxiosRequestConfig
) => api.put<T>(url, data, config).then((res) => res.data);

export const deletes = <T>(url: string, config?: AxiosRequestConfig) =>
  api.delete<T>(url, config).then((res) => res.data);

/** Posting data to NextJS Functions */
export const functionsPost = <T>(
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
) => axios.post<T>(url, data, config).then((res) => res.data);

import {
  CustomLink,
  CustomLinkProps,
} from "@crafthunt-ui/CustomLink/CustomLink";
import clsx from "clsx";

type CustomButtonProps = {
  children: React.ReactNode;
  href?: string;
  externalHref?: string;
  buttonStyle?: "primary" | "secondary";
} & (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">
  | CustomLinkProps
);

export const CustomButton = ({
  children,
  href,
  externalHref,
  className,
  buttonStyle = "primary",
  ...rest
}: CustomButtonProps) => {
  const buttonStyles = {
    primary:
      "bg-primary-600 font-semibold text-white shadow-md hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600",
    secondary: "font-medium text-black md:text-white md:hover:bg-gray-600",
  };
  const classes = clsx(
    className,
    "rounded-sm py-2 px-3.5 text-sm",
    buttonStyles[buttonStyle]
  );

  if (href) {
    return (
      <CustomLink
        className={classes}
        href={href}
        {...(rest as CustomLinkProps)}
      >
        {children}
      </CustomLink>
    );
  }

  if (externalHref) {
    return (
      <a
        className={classes}
        href={externalHref}
        {...(rest as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={classes}
      {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
};

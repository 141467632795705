import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import { CSSProperties } from "react";

type Props = {
  blok: {
    pretitle?: string;
    title?: string;
    subtitle?: string;
    items?: {
      title?: string;
      description?: string;
    }[];
    darkTheme?: boolean;
    headerAlign?: "left" | "center" | "right";
    verticalPadding?: "small" | "medium" | "large";
  };
  className?: string;
  style?: CSSProperties;
};

export const StatsSection2 = ({ blok, className, style }: Props) => {
  const { pretitle, title, items, subtitle, darkTheme, headerAlign } = blok;

  return (
    <div
      className={clsx(
        {
          "bg-gray-50 text-gray-900": !darkTheme,
          "bg-gray-900 text-white": darkTheme,
          "py-8 sm:py-12": blok.verticalPadding === "small",
          "py-16 sm:py-24": blok.verticalPadding === "medium",
          "py-24 sm:py-32":
            !blok.verticalPadding || blok.verticalPadding === "large",
        },
        className
      )}
      style={style}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {title && (
          <SectionHeader
            label={pretitle}
            description={title}
            content={subtitle}
            darkTheme={darkTheme}
            align={headerAlign}
          />
        )}
        <div className="mx-auto mt-16 max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            {items.map((item, index) => (
              <div
                key={index}
                className="mx-auto flex max-w-xs flex-col gap-y-4"
              >
                <dd className="order-first text-3xl font-semibold tracking-tight sm:text-5xl">
                  {item.title}
                </dd>
                <dt
                  className={clsx("text-base/7", {
                    "text-gray-600": !darkTheme,
                    "text-gray-400": darkTheme,
                  })}
                >
                  {item.description}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import EmailSubmitField from "@crafthunt-ui/EmailSubmitField";
import { NavbarCTA } from "@crafthunt-ui/NavbarCTA/NavbarCTA";
import { HubspotForm } from "components/forms/generic/HubspotForm";
import { HubspotMeeting } from "components/forms/generic/HubspotMeeting";
import { AboutUsContent } from "components/sections/AboutUsContent";
import { AboutUsHero } from "components/sections/AboutUsHero";
import { AboutUsValues } from "components/sections/AboutUsValues";
import Blog from "components/sections/Blog/Blog";
import { BlogCardsSection } from "components/sections/BlogCardsSection/BlogCardsSection";
import CTAFeatureScreenshot from "components/sections/CTAFeatureScreenshot/CTAFeatureScreenshot";
import CallToActionBanner from "components/sections/CallToActionBanner/CallToActionBanner";
import FaqSection from "components/sections/FaqSection";
import Feature from "components/sections/Feature";
import FeatureScreenshot from "components/sections/FeatureScreenshot/FeatureScreenshot";
import Grid from "components/sections/Grid";
import { ImageSection } from "components/sections/ImageSection";
import LandCTA from "components/sections/LandCTA";
import LandFeatureScreenshot from "components/sections/LandFeatureScreenshot";
import LandFeatures from "components/sections/LandFeatures/LandFeatures";
import LandHero from "components/sections/LandHero";
import LandTestimonial from "components/sections/LandTestimonial";
import LogoCloud from "components/sections/LogoCloud";
import LogoCloudSlider from "components/sections/LogoCloudSlider";
import LogoGrid from "components/sections/LogoGrid";
import Page from "components/sections/Page/Page";
import PolicyPage from "components/sections/PolicyPage";
import Reviews from "components/sections/Reviews";
import { RichTextContent } from "components/sections/RichTextContent";
import { Showcase } from "components/sections/Showcase/Showcase";
import { Spacer } from "components/sections/Spacer";
import { StatsSection } from "components/sections/StatsSection";
import { StatsSection2 } from "components/sections/StatsSection2";
import { TeamSection } from "components/sections/TeamSection";
import TestimonialGrid from "components/sections/TestimonialGrid";
import { Text } from "components/sections/Text/Text";
import { Video } from "components/sections/Video";
import YouCanBookMe from "components/sections/YouCanBookMe";
import dynamic from "next/dynamic";

export const dynamic_components = Object.freeze({
  grid: Grid,
  feature: Feature,
  page: Page,
  LandHero,
  LandFeatureScreenshot,
  LandTestimonial,
  LandBlogOverview: BlogCardsSection,
  LandCTA,
  EmailSubmitField,
  PolicyPage,
  LandFeatures,
  blog: Blog,
  calltoactionbanner: CallToActionBanner,
  featuretailwind: FeatureScreenshot,
  ctafeaturescreenshot: CTAFeatureScreenshot,
  logocloudslider: LogoCloudSlider,
  logocloud: LogoCloud,
  "hubspot-form": HubspotForm,
  "hubspot-meeting": HubspotMeeting,
  baugpt: dynamic(() => import("components/sections/BauGPT")),
  ycbm: YouCanBookMe,
  AboutUsHero,
  AboutUsContent,
  AboutUsValues,
  Reviews,
  StatsSection,
  StatsSection2,
  TeamSection,
  ImageSection,
  "testimonial-grid": TestimonialGrid,
  "logo-grid": LogoGrid,
  "faqs-section": FaqSection,
  spacer: Spacer,
  RichTextContent,
  Text,
  Showcase,
  AuthCTAButton,
  NavbarCTA,
  Video,
});

export type DynamicComponentNames = keyof typeof dynamic_components;

import { EJourneyTypes } from "constants/journey.constants";
import { useUtmParams } from "hooks/useUtmParams";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { forwardRef, MouseEvent } from "react";
import { triggerClickCTA } from "utils/analytics";
import { extractQueryFromUrl, getLinkTarget } from "utils/router.helper";

export type CustomLinkProps = NextLinkProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> & {
    isCTA?: boolean;
  };

export const CustomLink = forwardRef<HTMLAnchorElement, CustomLinkProps>(
  function CustomLink(
    { href, target, rel, onClick, isCTA = false, ...rest },
    ref
  ) {
    const utmParams = useUtmParams();

    const getLink = () => {
      if (typeof href === "string") {
        const { url, query } = extractQueryFromUrl(href);
        return { pathname: url, query: { ...query, ...utmParams } };
      }
      return {
        ...href,
        pathname: href.pathname,
        query: { ...utmParams, ...(href.query as object) },
      };
    };

    const linkTarget = getLinkTarget(
      typeof href === "string" ? href : href.pathname || href.href,
      target
    );

    const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (isCTA) {
        const url = new URL(e.currentTarget.href);
        triggerClickCTA({
          linkId: e.currentTarget.id,
          linkUrl: e.currentTarget.href,
          linkName:
            typeof rest.children === "string" ? rest.children : undefined,
          journey:
            (url.searchParams.get("fromJourney") as EJourneyTypes) || undefined,
        });
      }
      onClick?.(e);
    };

    return (
      <NextLink
        href={getLink()}
        target={linkTarget}
        rel={rel || linkTarget === "_blank" ? "noreferrer" : undefined}
        onClick={handleOnClick}
        ref={ref}
        {...rest}
      />
    );
  }
);

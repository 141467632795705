import { fetcher } from "constants/api";
import { ENV } from "constants/env";
import useSWR from "swr";
import { JobAdsListDef, JobAdsRequestDef } from "types/job-ad.types";
import { convertLocaleForBackend } from "utils/language.helper";

export function useGetJobAds({
  shouldFetch = true,
  isLoggedIn = false,
  ...params
}: JobAdsRequestDef & {
  shouldFetch?: boolean;
  isLoggedIn?: boolean;
}) {
  const { data, isValidating, error } = useSWR<JobAdsListDef>(
    shouldFetch
      ? [
          "/crafthunts/job_ads",
          {
            ...params,
            lang: params.lang
              ? convertLocaleForBackend(params.lang)
              : undefined,
          },
          isLoggedIn
            ? {}
            : {
                "x-access-token": ENV.BACKEND_API_KEY,
                Authorization: "",
              },
        ]
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    }
  );

  return {
    jobAds: data?.data || [],
    metadata: {
      currentPage: data?.currentPage || 0,
      totalPages: data?.totalPages || 0,
      count: data?.count || 0,
      limit: data?.limit || 0,
      offset: data?.offset || 0,
    },
    isLoading: (!error && typeof data === "undefined") || isValidating,
    error,
  };
}

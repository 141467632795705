import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import AppDownloadLink from "@crafthunt-ui/AppDownloadLink/AppDownloadLink";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import EmailSubmitField from "@crafthunt-ui/EmailSubmitField";
import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { StarsRating } from "@crafthunt-ui/StarsRating";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";
import { getTranslatedSlug } from "utils/router.helper";
import { v4 } from "uuid";

const LandHero = ({
  blok,
  settings,
  className = undefined,
  style = undefined,
}) => {
  const checkmarkList = [
    blok.first_checkmark_label,
    blok.second_checkmark_label,
  ].filter(Boolean);

  return (
    <div
      className={clsx(
        "bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pb-14 lg:pt-8",
        className
      )}
      style={style}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto max-w-7xl px-10 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:py-8 lg:pr-4">
            {blok.preheaderLink &&
              (blok.preheaderLabel || blok.preheaderText) && (
                <div>
                  <CustomLink
                    href={getTranslatedSlug(blok.preheaderLink)}
                    target={blok.preheaderLink?.target}
                    className="inline-flex space-x-6"
                  >
                    {blok.preheaderLabel && (
                      <span className="rounded-full bg-primary-500/10 px-3 py-1 text-sm/6 font-semibold text-primary-400 ring-1 ring-inset ring-primary-500/10">
                        {blok.preheaderLabel}
                      </span>
                    )}
                    {blok.preheaderText && (
                      <span className="inline-flex items-center space-x-2 text-sm/6 font-medium text-gray-300">
                        <span>{blok.preheaderText}</span>
                        <ChevronRightIcon
                          aria-hidden="true"
                          className="size-5 text-gray-400"
                        />
                      </span>
                    )}
                  </CustomLink>
                </div>
              )}
            {blok.markdownTitle ? (
              <div className="mt-4 text-4xl font-medium tracking-tight text-white sm:mt-5 sm:text-5xl lg:mt-6 [&>strong]:font-extrabold">
                <Markdown
                  renderer={{
                    codespan(code) {
                      return (
                        <span
                          key={v4()}
                          className="bg-gradient-to-r from-primary-200 to-primary-400 bg-clip-text text-transparent"
                        >
                          {code}
                        </span>
                      );
                    },
                  }}
                >
                  {blok.markdownTitle}
                </Markdown>
              </div>
            ) : (
              <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                {blok.title && <span className="block">{blok.title}</span>}
                {blok.colorTitle && (
                  <span className="block bg-gradient-to-r from-primary-200 to-primary-400 bg-clip-text text-transparent">
                    {blok.colorTitle}
                  </span>
                )}
              </h1>
            )}
            <div className="prose-primary prose prose-invert pt-3 text-gray-300 sm:prose-xl lg:prose-lg xl:prose-xl sm:pt-5">
              <Markdown>{blok.description}</Markdown>
            </div>
            {
              {
                ["appDownload"]: (
                  <div className="mt-6">
                    <AppDownloadLink />
                  </div>
                ),
                ["ctaButtons"]: (
                  <div className="mt-10 flex flex-wrap items-center gap-1 sm:gap-6 lg:mt-12 lg:shrink-0">
                    {!!blok.primaryBtnText && (
                      <CustomLink
                        isCTA
                        id={`cta-land-hero-primary-${blok._uid}`}
                        href={getTranslatedSlug(
                          blok.primaryBtnLink,
                          { fromJourney: blok.primaryBtnJourney },
                          "https://admin.crafthunt.app/signup"
                        )}
                        target={blok.primaryBtnLink?.target}
                        className="w-full rounded-sm bg-primary-600 px-5 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:w-auto"
                      >
                        {blok.primaryBtnText}
                      </CustomLink>
                    )}
                    {!!blok.secondaryBtnText && (
                      <CustomLink
                        isCTA
                        id={`cta-land-hero-secondary-${blok._uid}`}
                        href={getTranslatedSlug(
                          blok.secondaryBtnLink,
                          { fromJourney: blok.secondaryBtnJourney },
                          "https://link.crafthunt.app/termin"
                        )}
                        target={blok.secondaryBtnLink?.target}
                        className="w-full py-3 text-center text-sm font-semibold leading-6 text-white hover:text-primary-500 sm:w-auto"
                      >
                        {blok.secondaryBtnText}{" "}
                        <span aria-hidden="true">→</span>
                      </CustomLink>
                    )}
                  </div>
                ),
                ["emailAndPhone"]: (
                  <div className="mt-10 sm:mt-12">
                    <EmailSubmitField
                      id={`LandHero${settings?.emailSubmit[0]._uid}`}
                      blok={settings?.emailSubmit[0]}
                      hideDisclaimer
                      fromJourney={blok.signupFromJourney}
                    />
                  </div>
                ),
                ["authCTA"]: (
                  <div className="py-8 sm:py-7">
                    {blok.cta?.map((ctaBlok) => (
                      <AuthCTAButton key={ctaBlok._uid} blok={ctaBlok} />
                    ))}
                  </div>
                ),
              }[blok.ctaOptions]
            }
            {Boolean(blok.rating) && (
              <div className="mt-1 flex items-center justify-center gap-2 font-medium text-white sm:mt-4 sm:justify-start">
                <StarsRating rating={parseFloat(blok.rating)} />{" "}
                {blok.ratingText}
              </div>
            )}
            {!!blok.used_by_label && (
              <p className="mt-8 text-base font-semibold text-white sm:mt-7">
                {blok.used_by_label}
              </p>
            )}
            {!!checkmarkList.length && (
              <div className="mt-2 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                <ul
                  role="list"
                  className="mt-1 grid grid-cols-2 gap-x-8 gap-y-3 text-base leading-7 text-white"
                >
                  {checkmarkList.map((checkmark) => (
                    <li key={checkmark} className="flex gap-x-3">
                      <CheckCircleIcon
                        className="h-7 w-5 flex-none"
                        aria-hidden="true"
                      />
                      {checkmark}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="aspect-h-4 aspect-w-5 relative md:-mb-2 lg:my-10 lg:pl-4">
            <NextImage
              className="rounded-lg object-cover xl:shadow-lg"
              src={transformImage(
                blok.image.filename,
                "1500x0/filters:quality(80)"
              )}
              alt="landing page hero image"
              fill
              priority
              sizes="(max-width: 767px) 100vw, (max-width: 1023px) 80vw, (max-width: 2000px) 50vw, 30vw"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandHero;

import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import AppDownloadLink from "@crafthunt-ui/AppDownloadLink/AppDownloadLink";
import EmailSubmitField from "@crafthunt-ui/EmailSubmitField";
import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import clsx from "clsx";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";

const LandCTA = ({
  blok = undefined,
  settings,
  className = undefined,
  style = undefined,
}) => {
  const authCta = blok?.cta?.length ? blok.cta[0] : undefined;
  const bottomCta = settings?.bottomCta?.length
    ? settings?.bottomCta[0]
    : undefined;
  return (
    <div className={clsx("relative bg-gray-900", className)} style={style}>
      {!!bottomCta?.image?.filename && (
        <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <NextImage
            className="h-full w-full object-cover"
            src={transformImage(
              bottomCta.image.filename,
              "1500x0/filters:quality(80)"
            )}
            alt="landing page call to action"
            fill
            sizes="(max-width: 767px) 100vw, 50vw"
          />
          <div
            aria-hidden="true"
            className="absolute inset-0 bg-gradient-to-r from-primary-500 to-primary-600 mix-blend-multiply"
          />
        </div>
      )}
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          {!!bottomCta?.label && (
            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
              {bottomCta.label}
            </h2>
          )}
          {!!bottomCta?.title && (
            <p className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
              {bottomCta.title}
            </p>
          )}
          <div className="prose-primary prose mt-3 text-gray-300 sm:prose-xl lg:prose-lg xl:prose-xl">
            <Markdown>{settings?.bottomCta[0].description}</Markdown>
          </div>
          {authCta && (
            <div className="mt-6">
              <AuthCTAButton blok={authCta} />
            </div>
          )}
          {!authCta && !!bottomCta?.showAppDownload && (
            <div className="mt-6">
              <AppDownloadLink />
            </div>
          )}
          {!authCta &&
            !bottomCta?.showAppDownload &&
            !!settings?.emailSubmit?.length && (
              <div className="mt-8">
                <EmailSubmitField
                  id={`LandCTA_${settings.emailSubmit[0]._uid}`}
                  blok={settings.emailSubmit[0]}
                  fromJourney={blok?.fromJourney}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default LandCTA;

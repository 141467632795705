import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { AuthCTAButtonDef } from "@auth/types/auth-cta-button.types";
import { ConditionalWrapper } from "@crafthunt-ui/ConditionalWrapper";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { Image } from "@crafthunt-ui/Image";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import { EColors } from "constants/colors.constants";
import { EJourneyTypes } from "constants/journey.constants";
import Link from "next/link";
import { CSSProperties } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { ImageDef } from "types/image.types";
import { StoryblokLinkDef } from "types/storyblok.types";
import { getFullSlug, getTranslatedSlug } from "utils/router.helper";

type FeatureScreenshotProps = {
  blok: {
    _uid: string;
    featureDirection?: "left" | "right";
    image?: ImageDef[];
    quote?: any[];
    /** @deprecated */
    color?: "white" | "gray-100";
    backgroundColor?: EColors;
    withRoundBorder?: boolean;
    withBoxShadow?: boolean;
    withCompactSpacing?: boolean;

    preHeader?: EColors;
    header?: EColors;
    description?: object;
    cta?: AuthCTAButtonDef[];
    cta_link?: StoryblokLinkDef;
    cta_journey?: EJourneyTypes;
    cta_text?: string;
  };
  className?: string;
  style?: CSSProperties;
};

const FeatureScreenshot = ({
  blok,
  className = undefined,
  style = undefined,
}: FeatureScreenshotProps) => {
  const bgColorVariants = {
    [EColors.WHITE]: "bg-white",
    [EColors.GRAY]: "bg-gray-100",
    [EColors.DARK_BLUE]: "bg-gray-900",
    [EColors.AMBER]: "bg-primary-500",
  };
  const textColorVariants = {
    [EColors.WHITE]: "text-black",
    [EColors.GRAY]: "text-black",
    [EColors.DARK_BLUE]: "text-white",
    [EColors.AMBER]: "text-white",
  };
  const preHeaderColorVariants = {
    [EColors.WHITE]: "text-primary-600",
    [EColors.GRAY]: "text-primary-600",
    [EColors.DARK_BLUE]: "text-primary-600",
    [EColors.AMBER]: "",
  };
  const primaryCTAColorVariants = {
    [EColors.WHITE]: "bg-primary-600 hover:bg-primary-700 text-white",
    [EColors.GRAY]: "bg-primary-600 hover:bg-primary-700 text-white",
    [EColors.DARK_BLUE]: "bg-primary-600 hover:bg-primary-700 text-white",
    [EColors.AMBER]: "bg-white text-primary-600",
  };

  const isLeftSideContent = blok.featureDirection === "left" ? true : false;
  const hasAtLeastOneImage = !!blok.image.length;
  const quoteCtaLink =
    blok.quote?.length && blok.quote[0].profile_link
      ? getTranslatedSlug(blok.quote[0].profile_link)
      : null;
  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        "overflow-hidden",
        bgColorVariants[
          blok.backgroundColor ||
            (blok.color === "gray-100" ? EColors.GRAY : EColors.WHITE)
        ],
        textColorVariants[
          blok.backgroundColor ||
            (blok.color === "gray-100" ? EColors.GRAY : EColors.WHITE)
        ],
        blok.withCompactSpacing ? "py-8" : "py-24",
        blok.withRoundBorder && "rounded-lg",
        blok.withBoxShadow && "border shadow-lg",
        className?.replace("not-prose", "")
      )}
      style={style}
    >
      {/*  Left Text & Right Screenshot */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div
          className={clsx(
            "mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none",
            hasAtLeastOneImage && "lg:grid-cols-2"
          )}
        >
          <div
            className={clsx("lg:pt-4", {
              "lg:pr-8": isLeftSideContent,
              "lg:pl-8": !isLeftSideContent,
            })}
          >
            <div className={clsx(hasAtLeastOneImage && "lg:max-w-lg")}>
              <h2
                className={clsx(
                  "not-prose text-base font-semibold leading-7",
                  preHeaderColorVariants[blok.backgroundColor || EColors.WHITE]
                )}
              >
                {blok.preHeader}
              </h2>
              <p className="not-prose mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                {blok.header}
              </p>
              <div
                className={clsx(
                  "prose-primary prose mt-6 max-w-none text-left text-xl leading-relaxed tracking-wide",
                  textColorVariants[
                    blok.backgroundColor ||
                      (blok.color === "gray-100" ? EColors.GRAY : EColors.WHITE)
                  ]
                )}
              >
                {render(blok.description)}
              </div>
            </div>
            {!!blok.cta?.length && (
              <div className="not-prose py-8">
                {blok.cta.map((ctaBlok) => (
                  <AuthCTAButton key={ctaBlok._uid} blok={ctaBlok} />
                ))}
              </div>
            )}
            {!!blok.cta_text && (
              <div className="not-prose py-8">
                <CustomLink
                  isCTA
                  id={`cta-feature-screenshot-${blok._uid}`}
                  href={getTranslatedSlug(blok.cta_link, {
                    fromJourney: blok.cta_journey,
                  })}
                  target={blok.cta_link?.target}
                  className={clsx(
                    "inline-block rounded-sm px-5 py-3 text-lg font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600",
                    primaryCTAColorVariants[
                      blok.backgroundColor || EColors.WHITE
                    ]
                  )}
                >
                  {blok.cta_text}
                  <span className="pl-2" aria-hidden="true">
                    →
                  </span>
                </CustomLink>
              </div>
            )}
            {/*  Checks if a quote is present */}
            {blok.quote?.length > 0 && (
              <figure className="not-prose mt-16 border-l border-gray-200 pl-8 text-gray-600">
                <blockquote className="text-base leading-7">
                  <p>{`"${blok.quote[0].quote}""`}</p>
                </blockquote>
                <figcaption className="mt-6 flex gap-x-4 text-sm leading-6">
                  <Image
                    src={blok.image[0].filename}
                    storyblokOption="0x600/filters:quality(80)"
                    alt=""
                    className="h-10 w-10 flex-none rounded-full"
                    sizes="(max-width: 767px) 60vw, (max-width: 1400px) 30vw, 20vw"
                  />
                  <div>
                    <span className="font-semibold text-gray-900">
                      {blok.quote[0].author}
                    </span>{" "}
                    {!!blok.quote[0].role && (
                      <ConditionalWrapper
                        condition={!!quoteCtaLink}
                        wrapper={(children) => (
                          <Link href={quoteCtaLink}>{children}</Link>
                        )}
                      >
                        {blok.quote[0].role}
                      </ConditionalWrapper>
                    )}
                  </div>
                </figcaption>
              </figure>
            )}
          </div>
          {/* Only one image was provided */}
          {blok.image.length === 1 && (
            <div
              className={clsx("not-prose aspect-h-4 aspect-w-5 relative", {
                "lg:order-second lg:ml-8": isLeftSideContent,
                "lg:order-first lg:mr-8": !isLeftSideContent,
              })}
            >
              <Image
                src={blok.image[0].filename}
                storyblokOption="800x0/filters:quality(100)"
                alt={`Feature Image ${blok.header}`}
                className="object-contain"
                fill
                quality={100}
                sizes="(max-width: 767px) 100vw, (max-width: 1023px) 80vw, (max-width: 1800px) 50vw, 30vw"
              />
            </div>
          )}
          {/* Multiple Images for Grid Layout where added */}
          {blok.image.length > 1 && (
            <div
              className={`not-prose -mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-3 lg:mr-10 ${
                isLeftSideContent ? "lg:order-second" : "lg:order-first"
              }`}
            >
              {blok.image.map((el) => {
                const imageLink = el.source && getFullSlug(el.source);
                return (
                  <ConditionalWrapper
                    key={el.id}
                    condition={!!imageLink}
                    wrapper={(children) => (
                      <Link href={imageLink} className="group">
                        {children}
                      </Link>
                    )}
                  >
                    <div className="relative flex h-28 items-center justify-center bg-gray-100 group-hover:bg-primary-100 lg:h-full">
                      <Image
                        className="object-contain px-16 py-6 md:px-6"
                        src={el.filename}
                        storyblokOption="0x200/filters:quality(80)"
                        alt={el.alt || "Company logo"}
                        fill
                        sizes="15vw"
                      />
                    </div>
                  </ConditionalWrapper>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureScreenshot;

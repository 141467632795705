import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { getStorage, setStorage, StorageTypeDef } from "./context.helper";
import { JobAdDef } from "types/job-ad.types";

type CandidateContextDef = {
  jobAdData?: JobAdDef;
  setJobAdData?: Dispatch<SetStateAction<JobAdDef>>;
  companyId?: string;
  setCompanyId?: Dispatch<SetStateAction<string>>;
  resetCandidate: () => void;
};

const JOB_AD_KEY = "jobAdData";
const COMPANY_KEY = "companyId";
const STORAGE_TYPE: StorageTypeDef = "sessionStorage";

const CandidateContext = createContext<CandidateContextDef | null>(null);

export function CandidateProvider({ children }) {
  const [jobAdData, setJobAdData] = useState<JobAdDef>(
    getStorage<JobAdDef>(JOB_AD_KEY, STORAGE_TYPE)
  );
  const [companyId, setCompanyId] = useState<string>(
    getStorage<string>(COMPANY_KEY, STORAGE_TYPE)
  );

  const resetCandidate = () => {
    setJobAdData(null);
    setCompanyId(null);
  };

  useEffect(() => {
    setStorage(JOB_AD_KEY, jobAdData, STORAGE_TYPE);
  }, [jobAdData]);

  useEffect(() => {
    setStorage(COMPANY_KEY, companyId, STORAGE_TYPE);
  }, [companyId]);

  return (
    <CandidateContext.Provider
      value={{
        jobAdData,
        setJobAdData,
        companyId,
        setCompanyId,
        resetCandidate,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
}

export function useCandidate() {
  return useContext(CandidateContext) as CandidateContextDef;
}

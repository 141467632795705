import { useEffect } from "react";

/**
 * This hook listens to the visibility change of the tab
 * and calls the onFocus function when the tab is focused.
 *
 * Source: https://davidwalsh.name/page-visibility
 */
export function useOnTabFocus(onFocus: () => void) {
  useEffect(() => {
    let state: string, visibilityChange: string;
    if (typeof document.hidden !== "undefined") {
      visibilityChange = "visibilitychange";
      state = "visibilityState";
    } else if (typeof document["mozHidden"] !== "undefined") {
      visibilityChange = "mozvisibilitychange";
      state = "mozVisibilityState";
    } else if (typeof document["msHidden"] !== "undefined") {
      visibilityChange = "msvisibilitychange";
      state = "msVisibilityState";
    } else if (typeof document["webkitHidden"] !== "undefined") {
      visibilityChange = "webkitvisibilitychange";
      state = "webkitVisibilityState";
    }
    const onVisible = () => {
      const visibilityState = document[state] as DocumentVisibilityState;
      if (visibilityState === "visible") {
        onFocus();
      }
    };

    document.addEventListener(visibilityChange, onVisible);
    return () => {
      document.removeEventListener(visibilityChange, onVisible);
    };
  }, [onFocus]);

  return;
}

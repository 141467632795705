import { fetcher } from "constants/api";
import { ENV } from "constants/env";
import useSWR from "swr";
import { convertLocaleForBackend } from "utils/language.helper";
import { CompanyDef } from "../types/companies.types";

type GetCompanyProps = {
  idOrSlug?: string;
  lang?: string;
  withJwt?: boolean;
};

export function useGetCompany({ idOrSlug, lang, withJwt }: GetCompanyProps) {
  const shouldFetchData = !!(idOrSlug && lang);
  const { data, isValidating, error } = useSWR<CompanyDef>(
    shouldFetchData
      ? [
          `/crafthunts/company_resources/${idOrSlug}`,
          {
            lang: lang ? convertLocaleForBackend(lang) : undefined,
          },
          withJwt
            ? undefined
            : {
                // TODO: Remove this, when removed from backend
                "x-access-token": ENV.BACKEND_API_KEY,
                Authorization: "",
              },
        ]
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  return {
    company: data || null,
    isLoading:
      shouldFetchData &&
      ((!error && typeof data === "undefined") || isValidating),
    error,
  };
}

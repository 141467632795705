import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { UTM_PARAMS } from "utils/analytics";

// Custom Hook to get UTM parameters
export const useUtmParams = () => {
  const router = useRouter();
  const [utmParams, setUtmParams] = useState<Record<string, string>>({});

  const getAllUTMs = (query: { [key: string]: string | string[] }) => {
    const utmParams = Object.keys(query).reduce((acc, key) => {
      if (UTM_PARAMS.includes(key)) {
        acc[key] = query[key];
      }
      return acc;
    }, {});

    return utmParams;
  };

  useEffect(() => {
    const params = getAllUTMs(router.query);
    setUtmParams(params);
  }, [router.query]);

  return utmParams;
};
